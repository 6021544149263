export const ENGLISH_LANGUAGE = {
  code: "en",
  region: "en-US",
  name: "English",
  locale: "en",
  textDirection: "ltr",
} as const;

export const SPANISH_LANGUAGE = {
  code: "es",
  region: "es-ES",
  name: "Español",
  locale: "es",
  textDirection: "ltr",
} as const;

export const BRASILIAN_PORTUGUESE_LANGUAGE = {
  code: "pt-br",
  region: "pt-BR",
  name: "Português do Brasil",
  locale: "pt-br",
  textDirection: "ltr",
} as const;

export const ITALIAN_LANGUAGE = {
  code: "it",
  region: "it-IT",
  name: "Italiano",
  locale: "it",
  textDirection: "ltr",
} as const;

export const FRENCH_LANGUAGE = {
  code: "fr",
  region: "fr-FR",
  name: "Français",
  locale: "fr",
  textDirection: "ltr",
} as const;

export const GERMAN_LANGUAGE = {
  code: "de",
  region: "de-DE",
  name: "Deutsch",
  locale: "de",
  textDirection: "ltr",
} as const;

export const INDONESIAN_LANGUAGE = {
  code: "id",
  region: "id-ID",
  name: "Bahasa Indonesia",
  locale: "id",
  textDirection: "ltr",
} as const;

export const ARABIC_LANGUAGE = {
  code: "ar-SA",
  region: "ar-SA",
  name: "Arabic",
  locale: "ar-SA",
  textDirection: "rtl",
} as const;

export const SUPPORTED_LANGUAGES = [
  GERMAN_LANGUAGE,
  ENGLISH_LANGUAGE,
  SPANISH_LANGUAGE,
  FRENCH_LANGUAGE,
  ITALIAN_LANGUAGE,
  BRASILIAN_PORTUGUESE_LANGUAGE,
  INDONESIAN_LANGUAGE,
  ARABIC_LANGUAGE,
] as const;

export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];
export type SupportedLanguageCode = SupportedLanguage["code"];
export type SupportedTextDirections = SupportedLanguage["textDirection"];
