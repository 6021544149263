export const enum RequestHeadersNamesToBeForwarded {
  Country = "cloudfront-viewer-country",
  Forwarded = "x-forwarded-for",
  Timezone = "cloudfront-viewer-time-zone",
  IsIOS = "cloudfront-is-ios-viewer",
  UserAgent = "user-agent",
  CdnMarker = "x-cdn-identifier",
}

export type CountryCode = string;
