import type {
  SupportedTypedServerParameters,
  TrackingEvent,
} from "./tracking-event";
import type {
  EventType,
  Tracker as XpaTracker,
  TrackingEvent as XpaTrackingEvent,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import { TrackerType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { noop } from "@/utils/noop";
import type { Tracker } from "./tracker";
import type { TrackingEventTracker } from "./tracking-event-tracker";
import type { Immutable } from "@/types/immutable";
import type { TrackingEventAdapted } from "./tracking-event-adapted";
import { rudderstack } from "../../trackers/rudderstack/rudderstack-service";
import { typedServerParametersToObject } from "./transformTypedServerParams";

const supportedTrackers: ReadonlyArray<Tracker> = [rudderstack];

// a working implementation for the browser
const trackingServiceBrowser = {
  sendEvent(event: TrackingEvent): void {
    event.trackers.forEach((tracker) => {
      switch (tracker.kind) {
        case "rudderstack": {
          rudderstack.sendEvent(
            {
              ...event.params,
            },
            tracker.extraParams,
          );

          break;
        }
        default:
          return;
      }
    });
  },

  sendCustomEvent(event: TrackingEventAdapted): void {
    event.trackers.forEach((tracker) => {
      switch (tracker.type) {
        case TrackerType.TRACKER_RUDDERSTACK:
          rudderstack.sendEvent(event.params, { name: event.name });
          break;
        default:
          break;
      }
    });
  },

  setUserId(id: string): void {
    supportedTrackers.forEach((tracker) => {
      tracker.setUserId(id);
    });
  },

  resetUserId(): void {
    supportedTrackers.forEach((tracker) => {
      tracker.resetUserId();
    });
  },

  // just a convenient wrapper around `sendEvent` for XPA
  sendXpaTracking(
    trackingEvents: Immutable<Array<XpaTrackingEvent>>,
    eventType: EventType,
  ): void {
    trackingEvents.forEach((event) => {
      if (event.type === eventType) {
        const eventTest = xpaTrackerEventBuilder(event);
        this.sendEvent(eventTest);
      }
    });
  },

  sendSingleXpaTracking(
    event: Immutable<XpaTrackingEvent>,
    params: Record<string, SupportedTypedServerParameters> = {},
  ): void {
    this.sendEvent(xpaTrackerEventBuilder(event, params));
  },
} as const;

function xpaTrackerToTrackingEventTracker(
  xpaTracker: XpaTracker,
  event: Immutable<XpaTrackingEvent>,
): Readonly<
  { isSupported: true; tracker: TrackingEventTracker } | { isSupported: false }
> {
  switch (xpaTracker.type) {
    case TrackerType.TRACKER_RUDDERSTACK:
      return {
        isSupported: true,
        tracker: {
          kind: "rudderstack",
          extraParams: { name: event.name },
        },
      };
    case TrackerType.TRACKER_LOCALYTICS:
    case TrackerType.TRACKER_SNOWPLOW:
    case TrackerType.TRACKER_UNSPECIFIED:
    case TrackerType.TRACKER_FIREBASE:
    case TrackerType.TRACKER_ADJUST:
    case TrackerType.TRACKER_AIRSHIP:
    case TrackerType.TRACKER_GOOGLE:
    case TrackerType.TRACKER_FACEBOOK:
    case TrackerType.UNRECOGNIZED:
      return { isSupported: false };
  }
}

interface XpaTrackerEventBuilderResponse {
  params: Record<string, SupportedTypedServerParameters>;
  trackers: Array<TrackingEventTracker>;
}

function xpaTrackerEventBuilder(
  event: Immutable<XpaTrackingEvent>,
  params: Record<string, SupportedTypedServerParameters> = {},
): XpaTrackerEventBuilderResponse {
  const { typedServerParameter } = event;

  const serverParameterObject =
    typedServerParametersToObject(typedServerParameter);

  return {
    params: { ...params, ...serverParameterObject },
    trackers: event.trackers.flatMap((tracker) => {
      const trackingEventTracker = xpaTrackerToTrackingEventTracker(
        tracker,
        event,
      );

      if (trackingEventTracker.isSupported) {
        return trackingEventTracker.tracker;
      }

      return [];
    }),
  };
}

type TrackingService = typeof trackingServiceBrowser;

// tracking is not supposed to be used in server-side rendering
const trackingServiceNode: TrackingService = {
  sendEvent: noop,
  setUserId: noop,
  resetUserId: noop,
  sendXpaTracking: noop,
  sendSingleXpaTracking: noop,
  sendCustomEvent: noop,
};

/**
 * A facade for all supported trackers with noop behaviour during SSR.
 */
export const trackingService =
  typeof window === "undefined" ? trackingServiceNode : trackingServiceBrowser;
