import type { NavigationSections } from "@motain/xpa-proto-files-web/lib/types/navigation_bar";

export async function lazyInitSubNavRuntime(): Promise<
  (input: Uint8Array, length?: number) => NavigationSections
> {
  const [
    {
      NavigationSections: { decode },
    },
  ] = await Promise.all([
    import("@motain/xpa-proto-files-web/lib/runtime/navigation_bar"),
  ]);
  return decode;
}
