import { ORIGIN } from "./origin.constant";
import type { ForcedDataOption } from "./forced-data-options.constant";
import { apiConfigStaging } from "./api-configs/api-config-staging";
import { apiConfigProd } from "./api-configs/api-config-prod";
import { apiConfigPreprod } from "./api-configs/api-config-preprod";
import { apiConfigLocalWebExperience } from "./api-configs/api-config-local-web-experience";
import { apiConfigHttpsLocalWebExperience } from "./api-configs/api-config-https-local-web-experience";
import { browserCookie } from "@/utils/browserCookie";

export interface EnvBasedConfigApi {
  adyen: Readonly<{
    /**
     * In production, we should set `live` if we are ready to accept payments.
     * @see https://docs.adyen.com/online-payments/components-web#testing-your-integration.
     */
    env: "live" | "test";

    /**
     * To find out how to generate one:
     * @see https://docs.adyen.com/development-resources/client-side-authentication.
     */
    clientKey: `${"live" | "test"}_${string}`;
  }>;
  /**
   * Remote logs collector URL
   */
  loggerUrl: `https://pappagallu${"" | "-stg"}.onefootball.com/log`;
  imageServiceUrl: `https://image-service${"" | "-stg"}.onefootball.com`;
  webExperience: string;
  paymentsHistory: string;
  webPaymentsBackend: string;
  usersAuth: string;
  youboraAccountCode: string;
  openwebSpotId: string;
  rudderstckKey: string;
  rudderstckDataPlane: string;
}

/**
 * Configuration of features that are enabled in a given environment.
 */
export type EnvBasedConfigFeatureFlags = Readonly<
  Record<
    // keep this type multi-line for fewer merge conflicts
    | "adsAltura"
    | "expiredMatch"
    | "klarnaPayment"
    | "sofortPayment"
    | "recurrentPayments"
    | "refundPayment"
    | "paginationHome"
    | "extraTime"
    | "comscore"
    | "taboola"
    | "yahoo"
    | "arabicLanguage",
    boolean
  >
>;

export interface EnvBasedConfig
  extends Readonly<
    EnvBasedConfigApi & {
      /**
       * Configuration of features that are enabled in a given environment.
       */
      featureFlags: EnvBasedConfigFeatureFlags;
    }
  > {}

const staging: EnvBasedConfig = {
  featureFlags: {
    adsAltura: true,
    expiredMatch: false,
    klarnaPayment: true,
    recurrentPayments: true,
    sofortPayment: true,
    refundPayment: true,
    paginationHome: true,
    extraTime: false,
    comscore: true,
    taboola: true,
    yahoo: true,
    arabicLanguage: false,
    ...getForcedFeatureFlags(),
  },
  ...apiConfigStaging,
};

const local: EnvBasedConfig = {
  ...staging,
  ...apiConfigHttpsLocalWebExperience,
};

const production: EnvBasedConfig = {
  featureFlags: {
    adsAltura: true,
    expiredMatch: true,
    klarnaPayment: false,
    recurrentPayments: true,
    sofortPayment: false,
    refundPayment: false,
    paginationHome: true,
    extraTime: false,
    comscore: true,
    taboola: true,
    yahoo: true,
    arabicLanguage: false,
    ...getForcedFeatureFlags(),
  },
  ...apiConfigProd,
};

const preprod: EnvBasedConfig = {
  featureFlags: {
    ...production.featureFlags,
    ...getForcedFeatureFlags(),
  },
  ...apiConfigPreprod,
};

export const ENVIRONMENT = detectEnvironment();
export type Environment = typeof ENVIRONMENT;

const configs: Readonly<Record<Environment, EnvBasedConfig>> = {
  production,
  preprod,
  staging,
  local,
};

/* Might be updated based on cookies in _app.ts */
export let envBasedConfig: EnvBasedConfig = getEnvironmentBasedConfig();

/**
 * During SSR, we check the `ENVIRONMENT` variable to determine the environment.
 * During CSR, we check the hostname to determine the environment because
 * the `ENVIRONMENT` variable is not available during CSR (exposing it to the
 * browser would require a build argument for docker, i.e. separate images for
 * production and staging).
 * In both SSR an CSR we fall back to the `local` environment.
 */
function detectEnvironment() {
  if (typeof window === "undefined") {
    switch (process.env.ENVIRONMENT) {
      case "production":
        return "production";
      case "preprod":
        return "preprod";
      case "staging":
        return "staging";
      default:
        return "local";
    }
  }

  if (window.location.href.startsWith(ORIGIN.production)) {
    return "production";
  }

  if (window.location.href.startsWith(ORIGIN.preprod)) {
    return "preprod";
  }

  if (window.location.href.startsWith(ORIGIN.staging)) {
    return "staging";
  }

  return "local";
}

export function applyChangesFromDataOptionCookie(
  dataOption: ForcedDataOption | undefined,
): void {
  switch (dataOption) {
    case "staging":
      envBasedConfig = {
        ...envBasedConfig,
        ...apiConfigStaging,
      };

      break;
    case "pre-prod":
      envBasedConfig = {
        ...envBasedConfig,
        ...apiConfigPreprod,
      };

      break;
    case "production":
      envBasedConfig = {
        ...envBasedConfig,
        ...apiConfigProd,
      };

      break;
    case "local-web-experience":
      envBasedConfig = {
        ...envBasedConfig,
        ...apiConfigLocalWebExperience,
      };

      break;
    case "https-local-web-experience":
      envBasedConfig = {
        ...envBasedConfig,
        ...apiConfigHttpsLocalWebExperience,
      };

      break;
    case "mock":
      // eslint-disable-next-line no-console
      console.warn(
        "Forced data option 'mock' is not implemented! Fallback to env-based config.",
      );

      envBasedConfig = getEnvironmentBasedConfig();
      break;
    case undefined:
      envBasedConfig = getEnvironmentBasedConfig();
  }
}

function getEnvironmentBasedConfig(): EnvBasedConfig {
  return configs[ENVIRONMENT];
}

/**
 * Use for the feature flag page (no-prod-critical)
 * NO PRODUCTION CODE, DO NOT USE IN PRODUCTION
 */
function getForcedFeatureFlags():
  | EnvBasedConfigFeatureFlags
  | NonNullable<unknown> {
  const featureFlags = browserCookie.get("feature-flags");

  if (featureFlags === undefined) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {};
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/consistent-type-assertions
    const parsed = JSON.parse(featureFlags) as
      | EnvBasedConfigFeatureFlags
      | undefined;
    if (parsed === undefined) {
      return {};
    }
    return parsed;
  } catch (_) {
    return {};
  }
}
