import Cookie from "js-cookie";

export type CookieOptions = Readonly<{
  key: string;
  value: string;
  expires: Date;
}>;

// only relevant for deployed app running on https
const secure = ["production", "staging"].includes(process.env.NODE_ENV);

export const browserCookie = {
  put(options: CookieOptions): void {
    Cookie.set(options.key, options.value, {
      expires: options.expires,
      sameSite: "strict",
      secure,
    });
  },

  get(key: string): string | undefined {
    return Cookie.get(key);
  },

  remove(key: string): void {
    Cookie.remove(key);
  },
};
