import { envBasedConfig } from "@/constants/env-based-config.constant";
import { noop } from "@/utils/noop";
import type { GoogleAdsPlaceholder_Targeting } from "@motain/xpa-proto-files-web/lib/types/google_ads_placeholder";

export type GptServiceSizeMapping = Array<
  [
    [resolutionWidth: number, resolutionHeight: number],
    Array<[adsMinWidth: number, adsMinHeight: number]>,
  ]
>;

const slots = new Map<string, googletag.Slot>();
let ppid = "";

/*
 * Service for working with Google Publisher Tag
 * */
const gptBrowser = {
  addSlot(
    adPath: string,
    id: string,
    sizeMapping: GptServiceSizeMapping,
    targeting: Array<GoogleAdsPlaceholder_Targeting>,
  ) {
    googletag.cmd.push(function () {
      if (slots.has(id)) {
        return;
      }

      const slotSize: googletag.GeneralSize = sizeMapping.flatMap(
        (mapping) => mapping[1],
      );

      const processedMapping = sizeMapping
        .reduce(
          (acc, value) => acc.addSize(value[0], value[1]),
          googletag.sizeMapping(),
        )
        .build();

      let slot = googletag
        .defineSlot(adPath, slotSize, id)
        ?.addService(googletag.pubads())
        ?.defineSizeMapping(processedMapping ?? []);

      targeting.forEach((targetingPair) => {
        slot = slot?.setTargeting(targetingPair.key, targetingPair.value);
      });

      if (slot) {
        slots.set(id, slot);
      }

      googletag.pubads().setPublisherProvidedId(ppid);

      googletag.enableServices();
      if (envBasedConfig.featureFlags.adsAltura) {
        googletag.pubads().disableInitialLoad();
      }
    });
  },
  display(id: string) {
    googletag.cmd.push(function () {
      googletag.display(id);
    });
  },
  destroySlot(id: string) {
    googletag.cmd.push(function () {
      const slot = slots.get(id);

      if (slot !== undefined) {
        slots.delete(id);
        googletag.destroySlots([slot]);
      }
    });
  },

  setPublisherProvidedId(id: string) {
    ppid = id;
  },
  clearPublisherProvidedId() {
    ppid = "";
  },
};

const gptNode: typeof gptBrowser = {
  addSlot: noop,
  destroySlot: noop,
  display: noop,
  setPublisherProvidedId: noop,
  clearPublisherProvidedId: noop,
};

export const gptService = typeof window === "undefined" ? gptNode : gptBrowser;
