import type { Infer } from "superstruct";
import { is, literal, union } from "superstruct";

export const userTypeStruct = union([
  literal("facebook"),
  literal("firebase"),
  literal("google"),
  literal("apple"),
]);

export function isUserProviderType(
  value: unknown,
): value is Infer<typeof userTypeStruct> {
  return is(value, userTypeStruct);
}
