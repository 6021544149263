import type { GoogleAdsPlaceholder_GeneralSizes } from "@motain/xpa-proto-files-web/lib/types/google_ads_placeholder";
import { MEDIA_QUERY_MIN_WIDTH } from "@/constants/media-query.constant";

import type { GptServiceSizeMapping } from "../../services/gpt-service";
import { mapProtoResolutionToGPTResolution } from "./map-proto-resolution-to-gpt-resolution";

export function convertProtoSizesToGPTSizeMapping(
  sizes: GoogleAdsPlaceholder_GeneralSizes,
): GptServiceSizeMapping {
  return [
    [
      [MEDIA_QUERY_MIN_WIDTH.desktop, 0],
      sizes.desktop.map(mapProtoResolutionToGPTResolution),
    ],
    [
      [MEDIA_QUERY_MIN_WIDTH.tabletPortrait, 0],
      sizes.tablet.map(mapProtoResolutionToGPTResolution),
    ],
    [[0, 0], sizes.mobile.map(mapProtoResolutionToGPTResolution)],
  ];
}
