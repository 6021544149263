import { SnackbarContext } from "@/components/snackbar/Snackbar.context";
import { USERS_AUTH_API_CLIENT_SECRET } from "@/constants/users-auth-api-client-secret.constant";
import { AuthTokenCookie } from "@/enums/auth-token-cookie.enum";
import { SearchParam } from "@/enums/search-param.enum";
import { TOKEN_NAME_FOR_USERS_AUTH_API } from "@/services/email-auth-service";
import { trackingService } from "@/modules/analytics";
import { browserCookie } from "@/utils/browserCookie";
import { trackLoginPerformedEvent } from "@/utils/events/loginPerformed";
import type { SocialProvider } from "@/utils/getSocialProvider";
import { noop } from "@/utils/noop";
import { remoteLogger } from "@/utils/remoteLogger";
import { isError } from "@/utils/type-guards/errorTypeGuard";
import { isAppleIdTokenResponse } from "@/utils/type-guards/isAppleIdTokenResponseGuard";
import { nonEmptyStringStruct } from "@/utils/type-guards/isNonEmptyStringGuard";
import { isUserProviderType } from "@/utils/type-guards/isUserProviderType";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import type { FC, PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { is } from "superstruct";
import type { InitialUserState } from "@/utils/parseAppSpecificRequestCookies";
import {
  DEFAULT_NOTIFICATION_TIMEOUT_MS,
  SeverityType,
} from "@/components/snackbar/Snackbar";
import { USERS_AUTH_API, WEB_EXPERIENCE } from "@/constants/endpoints.constant";
import { isNonNullable } from "@/types/isNonNullable";
import { gptService } from "@/modules/ads";

type AuthenticationState = Readonly<
  | InitialUserState
  | {
      kind: "sign-in-via-email-in-progress";
      token: string;
      redirectUrl: string;
      origin: string;
    }
  | {
      kind: "sign-in-via-social-in-progress";
      provider: SocialProvider;
      redirectUrl: string;
      screen: "sign-in" | "sign-up";
      token: string;
      origin: string;
    }
>;

type StartSignInViaEmailArg = Readonly<
  Record<"token" | "redirectUrl" | "origin", string>
>;

interface FinishSignInViaEmailArg
  extends Readonly<{
    isMarketingEmailsAllowed: boolean;

    // intentionally `true` to indicate that consent should be provided
    isConsentProvided: true;

    screen: string;
    origin: string;
  }> {}

interface FinishSignUpViaEmailArg extends FinishSignInViaEmailArg {
  readonly token: string;
  readonly redirectUrl: string;
}

interface FinishSignInViaSocialArg extends FinishSignInViaEmailArg {
  readonly provider: SocialProvider;
  readonly redirectUrl?: string;
}

interface StartSignInViaSocialWithTokenArg
  extends Readonly<{
    redirectUrl: string;
    screen: "sign-in" | "sign-up";
    token: string;
    origin: string;
  }> {}

interface StartSignInViaAppleArg
  extends Readonly<{
    redirectUrl: string;
    origin: string;
  }> {}

type AuthenticationContext = Readonly<{
  authState: AuthenticationState;

  signOut: () => void;

  // Sign in via email has 2 steps:

  /**
   * 1. /sign-in page and the email&password form. On submission, we get a token
   * from the email auth provider and redirect to the missing-consent page.
   */
  startSignInViaEmail: (arg: StartSignInViaEmailArg) => void;

  /**
   * 2. /missing-consent page and consent checkboxes form. On submission, we
   * send a request to the users-api.
   */
  finishSignInViaEmail: (arg: FinishSignInViaEmailArg) => void;

  /**
   * Sign up via email has only 1 step because users have to accept our
   * Terms&Conditions on the sign-up page and we don't need to redirect to the
   * missing-consent page.
   */
  finishSignUpViaEmail: (arg: FinishSignUpViaEmailArg) => void;

  /*
  Sign in via social providers is slightly different across providers:

  - Google and Facebook use popup windows and token is available without a page
    reload -> can be stored in memory and is available immediately
  - Apple uses a page reload with auth callback endpoint and a token is
    available only in httpOnly cookie (we store it there intentionally in our
    `/apple/auth-callback` endpoint) -> we should retrieve it from our special
    `/apple/id-token` endpoint first before we use it

  In the end, there are the same 2 steps as in the email flow: start and finish.
   */

  /**
   * 1. (option 1) /sign-in and Google or Facebook popup windows. On submission,
   * we get a token and make a client-side redirect to the missing-consent page.
   * Both Google and Facebook flows have all required information on the sign-in
   * page.
   */
  startSignInViaGoogle: (arg: StartSignInViaSocialWithTokenArg) => void;
  startSignInViaFacebook: (arg: StartSignInViaSocialWithTokenArg) => void;

  /**
   * 1. (option 2) /sign-in page and Apple. Apple controls everything on their
   * web-site and just invokes our `/apple/auth-callback` endpoint with the
   * `id_token`. Our auth callback endpoint will set this token to httpOnly
   * cookie and inside this function we will retrieve it from our
   * `/apple/id-token` endpoint and use it to make a client-side request to
   * the Users-API to get a user's consent data on the missing-consent page.
   */
  startSignInViaApple: (arg: StartSignInViaAppleArg) => void;

  /**
   * 2. /missing-consent page and consent checkboxes form. On submission, we
   * send a request to the users-api. The same for all social providers.
   */
  finishSignInViaSocial: (arg: FinishSignInViaSocialArg) => void;
}>;

const AuthenticationSession = createContext<AuthenticationContext>({
  authState: { kind: "unauthenticated" },
  signOut: noop,
  startSignInViaEmail: noop,
  finishSignInViaEmail: noop,
  finishSignUpViaEmail: noop,
  startSignInViaGoogle: noop,
  startSignInViaFacebook: noop,
  startSignInViaApple: noop,
  finishSignInViaSocial: noop,
});

/**
 * Global authentication context allows checking if the user is authenticated.
 * At the beginning we rely on the userState in props. If AuthTokenCookie.Access
 * is obtained in runtime we can assume that the user is authenticated and
 * update the state accordingly.
 */
export const AuthenticationProvider: FC<
  PropsWithChildren<{ initialUserState: InitialUserState }>
> = ({ children, initialUserState }) => {
  const [state, setState] = useState<AuthenticationState>(initialUserState);
  const router = useRouter();
  const { t } = useTranslation("web-payments");
  const showNotification = useContext(SnackbarContext);

  const navigate = useCallback(
    async ({
      redirectUrl,
      argRedirect,
    }: {
      redirectUrl?: string;
      argRedirect?: string;
    }) => {
      const isAbsoluteUrl =
        (isNonNullable(redirectUrl) && /^https?:/.test(redirectUrl)) ||
        (isNonNullable(argRedirect) && /^https?:/.test(argRedirect));

      if (isAbsoluteUrl) {
        location.replace(
          isNonNullable(argRedirect) ? argRedirect : redirectUrl ?? "/",
        );

        return;
      }

      await router.push(
        isNonNullable(argRedirect) ? argRedirect : redirectUrl ?? "/",
      );
    },
    [router],
  );

  /**
   * During SSR, we use `initialUserId` which is coming from the request cookie.
   * During CSR, we use browser cookie to get a fresh value which can be set in
   * runtime (e.g. after login or refresh).
   */
  const getUserId = useCallback(() => {
    if (typeof window === "undefined") {
      return initialUserState.kind === "authenticated"
        ? initialUserState.user.id
        : undefined;
    }

    const cookieValue = browserCookie.get(AuthTokenCookie.UserId);

    return is(cookieValue, nonEmptyStringStruct) ? cookieValue : undefined;
  }, [initialUserState]);

  /**
   * During SSR, we use `initialUserProvider` which is coming from the request cookie.
   * During CSR, we use browser cookie to get a fresh value which can be set in
   * runtime (e.g. after login or refresh).
   */
  const getUserProvider = useCallback(() => {
    if (typeof window === "undefined") {
      return initialUserState.kind === "authenticated"
        ? initialUserState.user.provider
        : undefined;
    }

    const cookieValue = browserCookie.get(AuthTokenCookie.UserProvider);

    return isUserProviderType(cookieValue) ? cookieValue : undefined;
  }, [initialUserState]);

  useEffect(() => {
    if (state.kind === "unauthenticated") {
      trackingService.resetUserId();
    }
  }, [state]);

  const signOut = useCallback(() => {
    setState({ kind: "unauthenticated" });
    gptService.clearPublisherProvidedId();
  }, []);

  const startSignInViaEmail = useCallback(
    ({ token, redirectUrl, origin }: StartSignInViaEmailArg) => {
      /*
      We keep the token and redirectUrl in state so that we can use them later
      on the missing-consent page.
       */
      setState({
        kind: "sign-in-via-email-in-progress",
        token,
        redirectUrl,
        origin,
      });

      void router.push("/payments/missing-consent");
    },
    [router],
  );

  const loginViaEmail = useCallback(
    async function finishSignIn(arg: FinishSignUpViaEmailArg) {
      try {
        const queryParams = new URLSearchParams({
          [SearchParam.LoginConsentSeen]: arg.isConsentProvided.toString(),
          [SearchParam.LoginSubscribed]:
            arg.isMarketingEmailsAllowed.toString(),
        });
        const endpoint = `${USERS_AUTH_API}/login/email?${queryParams.toString()}`;

        const res = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            client_secret: USERS_AUTH_API_CLIENT_SECRET,
            [TOKEN_NAME_FOR_USERS_AUTH_API]: arg.token,
          }),
        });

        const userId = getUserId();
        const userProvider = getUserProvider();

        if (
          res.status === 200 &&
          userId !== undefined &&
          userProvider !== undefined
        ) {
          showNotification?.({
            message: t`LOGIN_SUCCESS`,
            type: SeverityType.success,
            dismissible: true,
            autoHideDuration: DEFAULT_NOTIFICATION_TIMEOUT_MS,
          });

          trackLoginPerformedEvent({
            origin: arg.origin,
            previous_screen: arg.redirectUrl,
            screen: arg.screen,
            source: "email",
            consent: "true", // arg.isConsentProvided is always `true`,
            marketing_consent: arg.isMarketingEmailsAllowed ? "true" : "false",
            userId,
          });

          await navigate({ argRedirect: arg.redirectUrl });

          // make sure the state is updated after the redirect
          setState({
            kind: "authenticated",
            user: { id: userId, provider: userProvider },
          });
        } else {
          showNotification?.({
            message: t`LOGIN_FAILED`,
            type: SeverityType.error,
            dismissible: true,
            autoHideDuration: DEFAULT_NOTIFICATION_TIMEOUT_MS,
          });

          setState({ kind: "unauthenticated" });
        }
      } catch (err) {
        remoteLogger.error(err, { _method: "POST", _status_code: 500 });

        showNotification?.({
          message: isError(err)
            ? err.message
            : "Login error. Please check /login/email request status.",
          type: SeverityType.error,
          dismissible: true,
          autoHideDuration: DEFAULT_NOTIFICATION_TIMEOUT_MS,
        });

        setState({ kind: "unauthenticated" });
      }
    },
    [getUserId, getUserProvider, showNotification, t, navigate],
  );

  const finishSignInViaEmail = useCallback(
    (arg: FinishSignInViaEmailArg) => {
      if (state.kind !== "sign-in-via-email-in-progress") {
        remoteLogger.error("finishSignInViaEmail called when not in progress");
        return;
      }

      void loginViaEmail({
        ...arg,
        token: state.token,
        redirectUrl: state.redirectUrl,
        origin: state.origin,
      });
    },
    [loginViaEmail, state],
  );

  const finishSignUpViaEmail = useCallback(
    (arg: FinishSignUpViaEmailArg) => {
      void loginViaEmail({
        ...arg,
        // we need to ask the user for optional email verification
        redirectUrl: `/payments/verify-email?${SearchParam.Redirect}=${arg.redirectUrl}`,
      });
    },
    [loginViaEmail],
  );

  const startSignInViaGoogle = useCallback(
    (arg: StartSignInViaSocialWithTokenArg) => {
      /*
      We keep the token and redirectUrl in state so that we can use them later
      on the missing-consent page.
       */
      setState({
        kind: "sign-in-via-social-in-progress",
        provider: "google",
        ...arg,
      });

      void router.push("/payments/missing-consent");
    },
    [router],
  );

  const startSignInViaFacebook = useCallback(
    (arg: StartSignInViaSocialWithTokenArg) => {
      /*
      We keep the token and redirectUrl in state so that we can use them later
      on the missing-consent page.
       */
      setState({
        kind: "sign-in-via-social-in-progress",
        provider: "facebook",
        ...arg,
      });

      void router.push("/payments/missing-consent");
    },
    [router],
  );

  const startSignInViaApple = useCallback((arg: StartSignInViaAppleArg) => {
    void startSignIn();

    async function startSignIn() {
      try {
        /*
        The Apple's `id_token` is available in httpOnly cookie, so we have to
        fetch it from our special endpoint
         */
        const endpoint = `${WEB_EXPERIENCE}/apple/id-token`;
        const response = await fetch(endpoint, { credentials: "include" });

        if (response.status !== 200) {
          remoteLogger.error(response.statusText);
          return;
        }

        const rawResponse: unknown = await response.json();

        if (!isAppleIdTokenResponse(rawResponse)) {
          remoteLogger.error("Unexpected Apple ID token response");
          return;
        }

        setState({
          token: rawResponse.token,
          kind: "sign-in-via-social-in-progress",
          provider: "apple",
          // we don't know if the user is signing up or signing in
          screen: "sign-up",
          ...arg,
        });
      } catch (err) {
        remoteLogger.error(err);
      }
    }
  }, []);

  const finishSignInViaSocial = useCallback(
    (arg: FinishSignInViaSocialArg) => {
      void finishSignIn();

      async function finishSignIn() {
        if (state.kind !== "sign-in-via-social-in-progress") {
          return;
        }

        try {
          const queryParams = new URLSearchParams({
            [SearchParam.LoginConsentSeen]: arg.isConsentProvided.toString(),
            [SearchParam.LoginSubscribed]:
              arg.isMarketingEmailsAllowed.toString(),
          });
          const endpoint = `${USERS_AUTH_API}/login/social?${queryParams.toString()}`;

          const res = await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              client_secret: USERS_AUTH_API_CLIENT_SECRET,
              social_provider: state.provider,
              social_token: state.token,
            }),
          });

          const userId = getUserId();
          const userProvider = getUserProvider();

          if (
            res.status == 200 &&
            userId !== undefined &&
            userProvider !== undefined
          ) {
            showNotification?.({
              message: t("SSO_SUCCESS", { provider: state.provider }),
              type: SeverityType.success,
            });

            trackLoginPerformedEvent({
              origin: state.origin,
              previous_screen: state.redirectUrl,
              screen: state.screen,
              source: state.provider,
              consent: "true", // arg.isConsentProvided is always `true`,
              marketing_consent: arg.isMarketingEmailsAllowed
                ? "true"
                : "false",
              userId,
            });

            await navigate({
              argRedirect: arg.redirectUrl,
              redirectUrl: state.redirectUrl,
            });

            // make sure the state is updated after the redirect
            setState({
              kind: "authenticated",
              user: { id: userId, provider: userProvider },
            });
          } else {
            showNotification?.({
              message: t("SSO_FAILED", { provider: state.provider }),
              type: SeverityType.error,
            });

            setState({ kind: "unauthenticated" });
          }
        } catch (err) {
          remoteLogger.error(err, { _method: "POST", _status_code: 500 });

          showNotification?.({
            message: isError(err)
              ? err.message
              : "Login error. Please check /login/social request status.",
            type: SeverityType.error,
          });

          setState({ kind: "unauthenticated" });
        }
      }
    },
    [getUserId, state, showNotification, t, getUserProvider, navigate],
  );

  return (
    <AuthenticationSession.Provider
      value={{
        authState: state,
        signOut,
        startSignInViaEmail,
        finishSignInViaEmail,
        finishSignUpViaEmail,
        startSignInViaGoogle,
        startSignInViaFacebook,
        startSignInViaApple,
        finishSignInViaSocial,
      }}
    >
      {children}
    </AuthenticationSession.Provider>
  );
};

export function useAuthentication(): AuthenticationContext {
  return useContext(AuthenticationSession);
}
