import { RudderstackEventName, trackingService } from "@/modules/analytics";

export interface LoginPerformedEvent
  extends Readonly<{
    /* screen name */
    previous_screen: string;

    /* screen name */
    screen: string;

    /* Apple, Google, Facebook, email */
    source: string;

    /*
    Whether a user gives consent for the terms and conditions and privacy
    policy. If you read this and think: "Why `false`? It's impossible to log in
    without consent, right?" - you're right. It's impossible but someone anyway
    put this `consent` property in iglu schemas and native apps started using
    this so fixing this is overcomplicated and no one had enough passion to push
    for this change. We keep `false` in the type union just for the previous
    flow without the /missing-consent page.
    */
    consent: "true";

    /* whether a user gives consent for marketing span emails */
    marketing_consent: "true" | "false";

    userId: string;

    /* String value that tells from which location is the user directed to login screen */
    origin: string;
  }> {}

export function trackLoginPerformedEvent({
  userId,
  ...restParams
}: LoginPerformedEvent): void {
  trackingService.setUserId(userId);
  const { origin, source, consent, marketing_consent } = restParams;

  trackingService.sendEvent({
    params: {
      login_source: source,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      consent: consent === "true", //the user can not click in the button without consent
      marketing_consent: marketing_consent === "true",
      login_origin: origin,
    },
    trackers: [
      {
        kind: "rudderstack",
        extraParams: { name: RudderstackEventName.LoginPerformed },
      },
    ],
  });
}
