import styles from "./YahooLogo.module.scss";
import type { FC } from "react";
import {
  EventType,
  TrackerType,
  type TrackingEvent,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import { RudderstackEventName, trackingService } from "@/modules/analytics";
import {
  ImageWithFallback,
  type ImageWithFallbackProps,
} from "../image/ImageWithFallback";

const yahooTrackingEventRudderstack: TrackingEvent = {
  type: EventType.EVENT_CLICK,
  name: RudderstackEventName.YahooClicked,
  clientParameterRequests: [],
  typedServerParameter: {},
  trackers: [
    {
      type: TrackerType.TRACKER_RUDDERSTACK,
      trackerParameters: {},
    },
  ],
  schema: "",
};

export const YahooLogo: FC<{ className?: string | undefined }> = ({
  className,
}) => {
  const onClick = () => {
    trackingService.sendXpaTracking(
      [yahooTrackingEventRudderstack],
      EventType.EVENT_CLICK,
    );
  };
  return (
    <div className={styles.wrapper}>
      <p className={className}>In partnership with</p>
      <a
        onClick={onClick}
        href="https://sports.yahoo.com/soccer/"
        target="_blank"
        className={styles.yahooLogo}
      >
        <span className="screen-reader-only">Yahoo sports</span>
        <ImageWithFallback {...YAHOO_ICON_FILLED_PROPS}></ImageWithFallback>
      </a>
    </div>
  );
};

export const YAHOO_ICON_FILLED_PROPS: ImageWithFallbackProps = {
  src: "https://images.onefootball.com/cw/icons/yahoo-logo-light.svg",
  alt: "",
  darkModeSrc: "images.onefootball.com/cw/icons/yahoo-logo-dark.svg",
  width: 98,
  height: 14,
  isPreloaded: true,
};
