import { useRouter } from "next/router";
import { SearchParam } from "@/enums/search-param.enum";
import { PROFILE_ACCOUNT_ROUTE } from "@/constants/routes.constant";

export function useLoginSearchParams(): URLSearchParams {
  const { query } = useRouter();
  const queryRedirect = query[SearchParam.Redirect] ?? PROFILE_ACCOUNT_ROUTE;
  const queryOrigin = query[SearchParam.Origin];
  const loginSearchParams = new URLSearchParams();

  loginSearchParams.set(SearchParam.Redirect, String(queryRedirect));

  if (queryOrigin !== undefined) {
    loginSearchParams.set(SearchParam.Origin, String(queryOrigin));
  }

  return loginSearchParams;
}
