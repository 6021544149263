import type { FC, ReactElement } from "react";
import useTranslation from "next-translate/useTranslation";
import { FooterMediaLink } from "./FooterMediaLink";
import type {
  FooterMediaLink as FooterMediaLinkType,
  FooterMediaLinksList,
} from "@/types/footer";
import styles from "./FollowOnefootball.module.scss";

export const FollowOnefootball: FC<{
  mediaLinksData: FooterMediaLinksList;
}> = (props): ReactElement => {
  const { t } = useTranslation("web-payments");

  return (
    <nav className={styles.followUsWrapper}>
      <h2 className={styles.header}>
        {t`MY_INTEREST_EMPTY_ENTITY_SUBTITLE`} OneFootball
      </h2>
      <ul className={styles.grid}>
        {props.mediaLinksData.map((lData: FooterMediaLinkType, i: number) => (
          <FooterMediaLink {...lData} key={i} />
        ))}
      </ul>
    </nav>
  );
};
