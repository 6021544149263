import { useEffect } from "react";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import type { TrackingEvent } from "@motain/xpa-proto-files-web/lib/runtime/tracking";
import { isNonNullable } from "@/types/isNonNullable";
import { TrackingEventName } from "../../services/tracking/tracking-event-name.enum";
import { trackingService } from "../../services/tracking/tracking-service";

/**
 * Tracks PageView from Xpa components
 */
export function useTrackingXpaPageView(
  xpaTracking: Array<TrackingEvent>,
): void {
  useEffect(() => {
    const trackerWithoutPageView = xpaTracking.filter(
      (tracker) => tracker.name !== TrackingEventName.PageView,
    );

    if (
      isNonNullable(trackerWithoutPageView) &&
      trackerWithoutPageView.length > 0
    ) {
      trackingService.sendXpaTracking(
        trackerWithoutPageView,
        EventType.SCREEN_VISIBLE,
      );
    }
  }, [xpaTracking]);
}
