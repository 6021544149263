// TODO: temporal enum meanwhile we migrate to rudderstack from localytics
export const enum RudderstackEventName {
  BookmarkClicked = "bookmark_clicked",
  CmsItemOpened = "cms_item_opened",
  CmsItemClicked = "cms_item_clicked",
  PageView = "page_view",
  LoginPerformed = "login_performed",
  PurchaseIntentFinished = "purchase_intent_finished",
  PurchaseIntent = "purchase_intent",
  VideoPlayed = "video_played",
  SelectedPayment = "selected_payment_method",
  LoginClicked = "login_clicked",
  PredictionMade = "prediction_made",
  PredictionViewed = "prediction_viewed",
  NavBarInteraction = "nav_bar_interaction",
  OnboardingStarted = "onboarding_started",
  YahooClicked = "yahoo_clicked",
}
