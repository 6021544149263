import type { LogMessage } from "./logger";
import { debug, error, log, warn } from "./logger";
import { envBasedConfig } from "@/constants/env-based-config.constant";

interface Logger {
  log: (message: string) => unknown;
  debug: (message: string) => unknown;
  warn: (message: string) => unknown;
  error: (error: unknown, data?: unknown) => unknown;
}

async function sendLogsToCollector(payload: LogMessage | undefined) {
  if (payload === undefined) {
    return;
  }

  try {
    await fetch(envBasedConfig.loggerUrl, {
      method: "POST",
      headers: {
        "X-Tracking-ID": payload._tracking_id,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  } catch (e) {
    error(`Failed to send logs to collector: ${JSON.stringify(payload)}`, e);
  }
}

// sends a log message to the remote logging system
export const remoteLogger: Logger = {
  log(message) {
    const payload = log(message);
    return sendLogsToCollector(payload);
  },
  debug(message) {
    const payload = debug(message);
    return sendLogsToCollector(payload);
  },
  warn(message) {
    const payload = warn(message);
    return sendLogsToCollector(payload);
  },
  error(err: unknown, data?: unknown) {
    const payload = error(err, data);
    return sendLogsToCollector(payload);
  },
};
