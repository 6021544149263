/**
 * All tracking events' names that we use use on the client side.
 * The list is not exhaustive because backend can send us anything in
 * XpaTrackingEvents.
 */
export const enum TrackingEventName {
  ArticleDetail = "ArticleDetail",
  ArticleReadCompleted = "ArticleReadCompleted",
  ArticleOpened = "Article opened",
  BannerClicked = "BannerClicked",
  CmsItemClicked = "CmsItemClicked",
  CmsItemViewed = "CmsItemViewed",
  Share = "Share",
  ComponentShown = "ComponentShown",
  DisplayMode = "DisplayMode",
  EntityClicked = "EntityClicked",
  EntityEntered = "Entity entered",
  FloatingScreen = "FloatingScreen",
  LanguageChange = "LanguageChange",
  LayeredBannerDownloadApp = "LayeredBannerDownloadApp",
  LayeredBannerSignUp = "LayeredBannerSignUp",
  LoadMoreMatches = "LoadMoreMatches",
  LoadMoreNews = "LoadMoreNews",
  MatchViewed = "MatchViewed",
  MatchesViewed = "MatchesStreamViewed",
  MatchItemClicked = "MatchItemClicked",
  NavBarInteraction = "NavBarInteraction",
  PageView = "PageView",
  PageViewSpace = "Page View",
  PromotionBannerClick = "PromotionBannerCtaClick",
  Search = "Search",
  SearchPerformed = "Search performed",
  VideoPlayed = "VideoPlayed",
  VideoAdPlayed = "VideoAdPlayed",
  DateChange = "DateChange",
  PredictionMade = "PredictionMade",
  PredictionViewed = "Prediction viewed",
  TransferItemClicked = "TransferItemClicked",
  LoginPerformed = "LoginPerformed",
  SelectedPayment = "SelectedPaymentMethod",
  PurchaseEventParams = "PurchaseEventParams",
  FinishedPurchaseParams = "FinishedPurchaseParams",
  OnboardingStarted = "OnboardingStarted",
  OnboardingFinished = "OnboardingFinished",
  EntityInteraction = "EntityInteraction",
  BookmarkClicked = "BookmarkClicked",
  DebugThankYouPage = "DebugThankYouPage",
}
