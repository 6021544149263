import { useState } from "react";
import { isNonNullable } from "@/types/isNonNullable";

export enum TransitionState {
  NotStarted,
  InProgress,
  Finished,
}

interface Delay {
  cancelDelay: () => void;
  delayPromise: Promise<unknown>;
}

const delay = (ms: number): Delay => {
  let cancelDelay = () => {};

  const delayPromise = new Promise((resolve, reject) => {
    const timeoutId = setTimeout(resolve, ms);

    cancelDelay = () => {
      clearTimeout(timeoutId);
      reject();
    };
  });

  return {
    cancelDelay,
    delayPromise,
  };
};

export const useTransition = (): [
  TransitionState,
  (transitionDuration: number) => Promise<unknown>,
  () => void,
] => {
  const [transitionState, setTransitionState] = useState<TransitionState>(
    TransitionState.NotStarted,
  );
  const [transitionDelay, setTransitionDelay] = useState<Delay>();

  const startTransition = (transitionDuration = 1000) => {
    setTransitionState(TransitionState.InProgress);

    const { delayPromise, cancelDelay } = delay(transitionDuration);

    setTransitionDelay({ delayPromise, cancelDelay });

    void delayPromise.then(() => {
      setTransitionState(TransitionState.Finished);
    });

    return delayPromise;
  };

  const resetTransition = () => {
    if (isNonNullable(transitionDelay)) {
      transitionDelay.cancelDelay();
    }
    setTransitionState(TransitionState.NotStarted);
  };

  return [transitionState, startTransition, resetTransition];
};
