import type { TypedServerParameter } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { ServerParameterType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { isNullable } from "@/types/isNullable";
import type { SupportedTypedServerParameters } from "./tracking-event";

export const typedServerParametersToObject = (
  typedServerParameters: Record<string, TypedServerParameter>,
): Record<string, SupportedTypedServerParameters> => {
  if (isNullable(typedServerParameters)) {
    return {};
  }

  return Object.keys(typedServerParameters).reduce((acc, key) => {
    const parameter = typedServerParameters[key];

    if (
      isNullable(parameter) ||
      isNullable(parameter.type) ||
      isNullable(parameter.value)
    ) {
      return acc;
    }

    const newValue = typedServerParametersParser(
      parameter.type,
      parameter.value,
    );

    return {
      ...acc,
      [key]: newValue,
    };
  }, {});
};

const typedServerParametersParser = (
  type: ServerParameterType,
  value: string,
) => {
  switch (type) {
    case ServerParameterType.FLOAT:
      return parseFloat(value);

    case ServerParameterType.BOOLEAN:
      return value === "true" ? true : false;

    case ServerParameterType.INTEGER:
      return parseInt(value);

    case ServerParameterType.STRING:
      return value;

    default:
      return;
  }
};
