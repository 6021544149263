import type { CookieCategory } from "../../domain/cookie-category";

export const cookieCategoryCode: CookieCategory = {
  strictNecessary: "C0001",
  performance: "C0002",
  functional: "C0003",
  targeting: "C0004",
  socialMedia: "C0005",
  deviceStorage: "IABV2_1",
  personalisedAdsAndContent: "STACK42",
} as const;
