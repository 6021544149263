import { envBasedConfig } from "@/constants/env-based-config.constant";
import type { SupportedTypedServerParameters } from "../../services/tracking/tracking-event";
import { remoteLogger } from "@/utils/remoteLogger";
import { history } from "../../useReferrer";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
declare type Rudderanalytics = typeof import("rudder-sdk-js");

const RUDDERSTACK_KEY = envBasedConfig.rudderstckKey;
const RUDDERSTACK_URL = envBasedConfig.rudderstckDataPlane;

interface Event {
  name: string;
  properties: Record<string, SupportedTypedServerParameters | undefined>;
}
interface Tracker {
  init: () => void;
  track: (
    event: string,
    properties: Record<string, SupportedTypedServerParameters | undefined>,
  ) => void;
  // @TODO Temporary
  identify: (userId: string) => void;
  reset: () => void;
}

let rudderanalytics: Rudderanalytics | undefined;
const queue: Array<Event> = [];

const processQueue = () => {
  if (!rudderanalytics) {
    return;
  }

  while (queue.length > 0) {
    const event = queue.shift();
    if (event) {
      rudderanalytics.track(event.name, event.properties);
    }
  }
};

export const rudderstack: Tracker = {
  init: () => {
    if (typeof window === "undefined") {
      throw new Error(`
        Rudderstack should be client-side rendered. 
        See: https://nextjs.org/docs/pages/building-your-application/rendering/client-side-rendering
      `);
    }

    import("rudder-sdk-js")
      .then((sdk) => {
        sdk.load(RUDDERSTACK_KEY, RUDDERSTACK_URL, {
          integrations: { All: true },
        });

        sdk.ready(() => {
          rudderanalytics = sdk;
          processQueue();
        });
      })
      .catch((error) => {
        remoteLogger.error(error);
      });
  },
  track: (event, properties) => {
    if (rudderanalytics === undefined) {
      queue.push({ name: event, properties });
      return;
    }

    rudderanalytics.track(event, properties, {
      context: {
        page: {
          referrer: history.referrer,
          referring_domain: history.referring_domain,
        },
      },
    });
  },
  // @TODO Temporary solution (for backward compatibility)
  identify: (userId) => {
    rudderanalytics?.identify(userId);
  },
  reset: () => {
    rudderanalytics?.reset();
  },
};
