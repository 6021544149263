import type { Tracker } from "../../services/tracking/tracker";
import { avoInspector } from "./avo-inspector";
import { streamName } from "../../services/stream-name-service";
import { browserCookie } from "@/utils/browserCookie";
import { AuthTokenCookie } from "@/enums/auth-token-cookie.enum";
import { isNonNullable } from "@/types/isNonNullable";
import { COOKIES_KEYS } from "@/constants/cookies.constants";
import { rudderstack as analytics } from "./analytics";

let countryCode = "unknown";

/*
 * We are not using cookies to avoid having conflicts with the GDPR, where non critical cookies have to asked for consent
 * since countryCode is not a critical cookie, we would have to add this cookie to OneTrust in order to avoid possible issues
 */
export const setRudderstackCountryCode = (
  countryCodeString: string | undefined,
): void => {
  if (countryCodeString !== undefined) {
    countryCode = countryCodeString.toLowerCase();
  }
};

const getSystemProperties = () => ({
  geo_country: countryCode, // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/dllRCCbAyQ
  screen: streamName.current, // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/APudkF5DUj
  previous_screen: streamName.previous, // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/oEGknmpDT1
  app_language: browserCookie.get(COOKIES_KEYS.UILang), // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/oEGknmpDT1/p/1eDqtOzJFk
  logged_in: isNonNullable(browserCookie.get(AuthTokenCookie.UserId)), // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/W5zyDdlDtA
  darkmode_enabled: browserCookie.get(COOKIES_KEYS.UIMode) === "enforced-dark", // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/eWmvfEJE4c
  platform: "of-web", // https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/properties/p/QzMPTqevTT
});

export interface RudderStackExtraParams
  extends Readonly<{
    name: string;
  }> {}

export const rudderstack: Tracker<RudderStackExtraParams> = {
  init: analytics.init,
  sendEvent(event, extraParams): void {
    const eventToSend = {
      ...event,
      ...getSystemProperties(),
    };

    avoInspector.trackSchemaFromEvent(extraParams.name, eventToSend);
    analytics.track(extraParams.name, eventToSend);
  },

  setUserId(userId): void {
    analytics.identify(userId);
  },

  resetUserId(): void {
    analytics.reset();
  },
} as const;
