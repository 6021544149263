module.exports = {
  en: {
    "all-competitions": "all-competitions",
    "all-teams": "all-teams",
    competition: "competition",
    home: "home",
    news: "news",
    match: "match",
    matches: "matches",
    "matches/tomorrow": "matches/tomorrow",
    "matches/yesterday": "matches/yesterday",
    player: "player",
    team: "team",
    search: "search",
    video: "video",
    transfers: "transfers",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses", // supported only in en
  },
  de: {
    "all-competitions": "alle-wettbewerbe",
    "all-teams": "alle-teams",
    competition: "wettbewerb",
    home: "home",
    news: "news",
    match: "spiel",
    matches: "spiele",
    "matches/tomorrow": "spiele/tomorrow",
    "matches/yesterday": "spiele/yesterday",
    player: "spieler",
    team: "team",
    search: "suche",
    video: "video",
    transfers: "transfers",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  id: {
    "all-competitions": "semua-kompetisi",
    "all-teams": "semua-tim",
    competition: "kompetisi",
    home: "beranda",
    news: "berita",
    match: "match",
    matches: "pertandingan",
    "matches/tomorrow": "pertandingan/tomorrow",
    "matches/yesterday": "pertandingan/yesterday",
    player: "pemain",
    team: "tim",
    search: "pencarian",
    video: "video",
    transfers: "transfer",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  es: {
    "all-competitions": "todas-las-competiciones",
    "all-teams": "todos-los-equipos",
    competition: "competicion",
    home: "inicio",
    news: "noticias",
    match: "partido",
    matches: "partidos",
    "matches/tomorrow": "partidos/tomorrow",
    "matches/yesterday": "partidos/yesterday",
    player: "jugador",
    team: "equipo",
    search: "busqueda",
    video: "video",
    transfers: "fichajes",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  fr: {
    "all-competitions": "toutes-les-competitions",
    "all-teams": "toutes-les-equipes",
    competition: "competition",
    home: "home",
    news: "news",
    match: "match",
    matches: "matchs",
    "matches/tomorrow": "matchs/tomorrow",
    "matches/yesterday": "matchs/yesterday",
    player: "joueur",
    team: "equipe",
    search: "recherche",
    video: "video",
    transfers: "transferts",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  it: {
    "all-competitions": "tutte-le-competizioni",
    "all-teams": "tutte-le-squadre",
    competition: "competizione",
    home: "home",
    news: "notizie",
    match: "partita",
    matches: "partite",
    "matches/tomorrow": "partite/tomorrow",
    "matches/yesterday": "partite/yesterday",
    player: "calciatore",
    team: "squadra",
    search: "ricerca",
    video: "video",
    transfers: "trasferimenti",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  "pt-br": {
    "all-competitions": "todas-as-competicoes",
    "all-teams": "todas-as-equipes",
    competition: "competicao",
    home: "inicio",
    news: "noticias",
    match: "match",
    matches: "jogos",
    "matches/tomorrow": "jogos/tomorrow",
    "matches/yesterday": "jogos/yesterday",
    player: "jogador",
    team: "time",
    search: "busca",
    video: "video",
    transfers: "transferencias",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
  "ar-SA": {
    "all-competitions": "all-competitions",
    "all-teams": "all-teams",
    competition: "competition",
    home: "home",
    news: "news",
    match: "match",
    matches: "matches",
    "matches/tomorrow": "matches/tomorrow",
    "matches/yesterday": "matches/yesterday",
    player: "player",
    team: "team",
    search: "search",
    video: "video",
    transfers: "transfers",
    account: "account",
    confirmation: "confirmation",
    "extra-time": "extra-time",
    "tv-hub": "tv-hub",
    licenses: "licenses",
  },
};
