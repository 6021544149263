
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "@/styles/globals.scss";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import Head from "next/head";
import type { ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PrivacyContextProvider } from "@/modules/privacy";
import { Snackbar } from "@/components/snackbar/Snackbar";
import { SnackbarContext } from "@/components/snackbar/Snackbar.context";
import { useNotification } from "@/utils/hooks/useNotification";
import type {
  InitialUserState,
  InsensitiveDataFromCookies,
} from "@/utils/parseAppSpecificRequestCookies";
import { parseAppSpecificRequestCookies } from "@/utils/parseAppSpecificRequestCookies";
import type { NextHeaders } from "@/utils/extractRequestHeaders";
import { extractRequestHeaders } from "@/utils/extractRequestHeaders";
import { ColorSchemaProvider } from "@/context/ColorSchema";
import { AuthenticationProvider } from "@/context/Authentication";
import { MatchPurchaseTransactionProvider } from "@/context/MatchPurchaseTransaction";
import { FinishPaymentProvider } from "@/context/FinishPayment";
import {
  ENVIRONMENT,
  applyChangesFromDataOptionCookie,
  envBasedConfig,
} from "@/constants/env-based-config.constant";
import { Header } from "@/components/header/Header";
import { Footer } from "@/components/footer/Footer";

import styles from "@/styles/App.module.scss";
import { BackendFeatureFlagsContextProvider } from "@/context/FeatureFlagsContext";
import { RequestInterceptorContainer } from "@/components/request-interceptor/RequestInterceptorContainer";
import { is } from "superstruct";
import { forcedDataOptionsStruct } from "@/constants/forced-data-options.constant";
import { useRouter } from "next/router";
import { browserCookie } from "@/utils/browserCookie";
import { COOKIES_KEYS } from "@/constants/cookies.constants";
import { getDateInXYears } from "@/utils/getDateInXYears";
import localFont from "@next/font/local";
import classNames from "classnames";
import { rudderstack, setRudderstackCountryCode } from "@/modules/analytics";
import Script from "next/script";
import { CountryCodeProvider } from "@/context/CountryCode";
import { isNonNullable } from "@/types/isNonNullable";

// Font files can be colocated inside of `pages`
const myFont = localFont({
  src: "./DrukTextLCG-Bold-subset.woff2",
  variable: "--Druk",
});

declare global {
  interface Window {
    _comscore?:
      | Array<unknown>
      | {
          beacon: (
            args: Record<"c1" | "c2" | "options", string | object>,
          ) => void;
        };
    COMSCORE?: {
      beacon: (args: Record<"c1" | "c2" | "options", string | object>) => void;
    };
  }
}

if (typeof window !== "undefined") {
  /* This code is used to store commands for Comscore while script is being loaded */
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window._comscore = window._comscore ?? [];

  if (Array.isArray(window._comscore)) {
    window._comscore.push({
      c1: "2",
      c2: "27061346",
      options: {
        enableFirstPartyCookie: true,
      },
    });
  }
}

export type NextPageProps = InsensitiveDataFromCookies &
  NextHeaders & {
    children?: ReactNode;
    userState: InitialUserState;
  };

type NextAppProps = AppProps & NextPageProps;

// provided by the users team
const GOOGLE_APP_ID_WEB =
  "693939097463-9flnrgkba55nfi9j5d2e401u57a16eh1.apps.googleusercontent.com";

function MyApp({
  Component,
  pageProps,
  uiMode,
  countryCode,
  forcedDataOption,
  userState,
}: NextAppProps): ReactElement {
  const { show, dismiss, ...snackbarProps } = useNotification();
  const { locale, defaultLocale = "en", asPath } = useRouter();

  if (typeof window !== "undefined") {
    browserCookie.put({
      key: COOKIES_KEYS.UILang,
      value: locale ?? defaultLocale,
      expires: getDateInXYears(5),
    });
  }

  setRudderstackCountryCode(countryCode);

  useEffect(() => {
    rudderstack.init();
  }, []);

  useEffect(() => {
    async function fetchPageCandidate() {
      try {
        const req = await fetch("https://onefootball.com/comscore");
        await req.text();
      } catch (error) {
        // fail silently
      }
    }

    if (ENVIRONMENT !== "production") {
      return;
    }

    if (typeof window !== "undefined" && isNonNullable(window.COMSCORE)) {
      // Comscore tracking implementation (Yahoo)
      // https://onefootball.atlassian.net/wiki/spaces/CWEB/pages/4240965644/Yahoo+Tracking
      // step 2

      window.COMSCORE.beacon({
        c1: "2",
        c2: "27061346",
        options: {
          enableFirstPartyCookie: true,
        },
      });

      // step 3
      void fetchPageCandidate();
    }
  }, [asPath]);

  if (
    typeof window !== "undefined" &&
    is(forcedDataOption, forcedDataOptionsStruct)
  ) {
    applyChangesFromDataOptionCookie(forcedDataOption);
  }

  const layout = (
    <>
      <main className={classNames(styles.main, myFont.variable)}>
        <Header />
        <SnackbarContext.Provider value={show}>
          <Component {...pageProps} uiMode={uiMode} countryCode={countryCode} />
        </SnackbarContext.Provider>
      </main>
      <Snackbar {...snackbarProps} onClose={dismiss} />
      <Footer countryCode={countryCode} />
    </>
  );

  return (
    <>
      <Head>
        <link rel="icon" href="/next-assets/favicon.png" />
        <link rel="manifest" href="/next-assets/manifest.json" />
      </Head>

      {envBasedConfig.featureFlags.comscore && (
        <>
          <Script
            src="https://sb.scorecardresearch.com/cs/27061346/beacon.js"
            strategy="afterInteractive"
          />
          <Head>
            <noscript>
              <img
                alt=""
                src="https://sb.scorecardresearch.com/p?c1=2&c2=27061346&cv=4.4.0&cj=1"
              />
            </noscript>
          </Head>
        </>
      )}
      <RequestInterceptorContainer>
        <BackendFeatureFlagsContextProvider>
          <FinishPaymentProvider>
            <MatchPurchaseTransactionProvider>
              <CountryCodeProvider InitialCountryCode={countryCode ?? ""}>
                <ColorSchemaProvider initialUiMode={uiMode}>
                  <AuthenticationProvider initialUserState={userState}>
                    <GoogleOAuthProvider clientId={GOOGLE_APP_ID_WEB}>
                      <PrivacyContextProvider>{layout}</PrivacyContextProvider>
                    </GoogleOAuthProvider>
                  </AuthenticationProvider>
                </ColorSchemaProvider>
              </CountryCodeProvider>
            </MatchPurchaseTransactionProvider>
          </FinishPaymentProvider>
        </BackendFeatureFlagsContextProvider>
      </RequestInterceptorContainer>
    </>
  );
}

/**
 * Although getInitialProps in App has some known caveats it is still required
 * to properly initialize App with OneFootball's uiMode, countryCode and userId.
 * @see {@link https://nextjs.org/docs/advanced-features/custom-app#caveats}
 * We can consider to remove this function in the future if:
 * - we simplify the themes (probably "auto" during SSR and proper theme in CSR)
 * - we use `getServerSideProps` instead of `getInitialProps` ot get userId
 * - we use `getServerSideProps` instead of `getInitialProps` ot get countryCode
 */
MyApp.getInitialProps = async (
  appContext: AppContext,
): Promise<NextPageProps> => {
  const headers = extractRequestHeaders(appContext.ctx);

  const cookies = parseAppSpecificRequestCookies(appContext.ctx);

  const appProps = await App.getInitialProps(appContext);

  const { forcedDataOption } = cookies;

  if (is(forcedDataOption, forcedDataOptionsStruct)) {
    applyChangesFromDataOptionCookie(forcedDataOption);
  }

  // IMPORTANT: don't expose sensitive data because it's inlined in the HTML
  return {
    ...appProps,
    ...headers,
    ...cookies,
  };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  