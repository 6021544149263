import type { FC, ReactElement } from "react";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import type { FooterMediaLink as FooterMediaLinkProps } from "@/types/footer";
import styles from "./FollowOnefootball.module.scss";

const ICON_SIZE = 24;

export const FooterMediaLink: FC<FooterMediaLinkProps> = ({
  urlPath,
  name,
  handle,
  iconUrl,
  darkModeIconUrl,
}): ReactElement => {
  return (
    <li className={styles.grid__card} key={name}>
      <a
        href={urlPath}
        className={styles.grid__card__link}
        rel="noreferrer"
        target="_blank"
      >
        <ImageWithFallback
          className={styles.grid__card__icon}
          alt={`${name} icon`}
          src={iconUrl}
          darkModeSrc={darkModeIconUrl}
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
        <span title={name}>{name}</span>
        <span className={styles.grid__card__link__handle} title={handle}>
          {handle}
        </span>
      </a>
    </li>
  );
};
