import {
  BRASILIAN_PORTUGUESE_LANGUAGE,
  FRENCH_LANGUAGE,
  GERMAN_LANGUAGE,
  ITALIAN_LANGUAGE,
} from "@/constants/languages.constant";

export const getOneFootballTvLocale = (locale: string): string => {
  switch (locale) {
    case GERMAN_LANGUAGE.locale:
      return "deu";
    case FRENCH_LANGUAGE.locale:
      return "fra";
    case ITALIAN_LANGUAGE.locale:
      return "ita";
    case BRASILIAN_PORTUGUESE_LANGUAGE.locale:
      return "br-pt";
    default:
      return "";
  }
};
