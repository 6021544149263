import type { UIMode } from "@/constants/themes.constant";
import { parse } from "cookie";
import { AuthTokenCookie } from "@/enums/auth-token-cookie.enum";
import type { NextPageContext } from "next";
import { is, type } from "superstruct";
import { nonEmptyStringStruct } from "@/utils/type-guards/isNonEmptyStringGuard";
import { userTypeStruct } from "./type-guards/isUserProviderType";
import type { ForcedDataOption } from "@/constants/forced-data-options.constant";
import { FORCED_DATA_OPTION_COOKIE_NAME } from "@/constants/forced-data-options.constant";
import { parseForcedDataOption } from "@/utils/parseForcedDataOption";
import { getUiModeFromCookies } from "@/utils/getUiModeFromCookies";

// safe to expose publicly in cookies, HTML etc.
export interface AuthenticatedUserState {
  readonly kind: "authenticated";
  readonly user: {
    readonly id: string;
    readonly provider: string;
  };
}

export interface UnauthenticatedUserState {
  readonly kind: "unauthenticated";
}

export type InitialUserState =
  | AuthenticatedUserState
  | UnauthenticatedUserState;

// safe to expose publicly in cookies, HTML etc.
export interface InsensitiveDataFromCookies {
  readonly uiMode: UIMode;
  readonly userState: InitialUserState;
  readonly forcedDataOption: ForcedDataOption | undefined;
}

/**
 * Parses only app-specific cookies like ui mode or tokens.
 */
export function parseAppSpecificRequestCookies(
  context: Partial<NextPageContext>,
): InsensitiveDataFromCookies {
  const req = context.req;
  const cookiesString =
    typeof window === "undefined" ? req?.headers.cookie : document.cookie;
  const cookies = parse(cookiesString ?? "");
  const parsedData = cookies[FORCED_DATA_OPTION_COOKIE_NAME];
  const uiMode = getUiModeFromCookies(cookies);

  return {
    uiMode: uiMode ?? "auto",
    userState: is(cookies, authenticatedUserCookiesStruct)
      ? {
          kind: "authenticated",
          user: {
            id: cookies[AuthTokenCookie.UserId],
            provider: cookies[AuthTokenCookie.UserProvider],
          },
        }
      : { kind: "unauthenticated" },
    forcedDataOption: parseForcedDataOption(parsedData),
  };
}

/**
 * We treat the user as authenticated only if we have the following cookies:
 * - non-empty `AuthTokenCookie.Refresh` (`AuthTokenCookie.Access` can be
 * obtained from the `AuthTokenCookie.Refresh` during any call to
 * web-experience)
 * - non-empty `AuthTokenCookie.UserId`
 * - non-empty `AuthTokenCookie.UserProvider`
 *
 * `AuthTokenCookie.UserId` and `AuthTokenCookie.UserProvider` could be taken
 * from the decoded `AuthTokenCookie.Refresh` token but we decided not to do
 * that in consumer-web-v3 and we let web-experience to do that. There are
 * high chances that soon we would need to introduce a new endpoint in
 * web-experience for user related settings (e.g. id, provider, favourite
 * team, avatar etc.). When we do that we might not need to use any of these
 * cookies and instead we would need to call this new endpoint every time we
 * start the app to get the user data.
 */
const authenticatedUserCookiesStruct = type({
  [AuthTokenCookie.Refresh]: nonEmptyStringStruct,
  [AuthTokenCookie.UserId]: nonEmptyStringStruct,
  [AuthTokenCookie.UserProvider]: userTypeStruct,
});
