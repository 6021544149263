import type { PropsWithChildren, ReactElement } from "react";
import { useEffect, useCallback, useRef } from "react";
import { useRouter } from "next/router";
import { fetchInterceptors } from "@/utils/fetchInterceptors";
import isBrowser from "is-in-browser";

export const REQUEST_INTERCEPTOR_EVENT_NAME = "requestInterceptorEvent";

// Extend GlobalEventHandlersEventMap to avoid a Typescript error while using this event
declare global {
  interface GlobalEventHandlersEventMap {
    [REQUEST_INTERCEPTOR_EVENT_NAME]: CustomEvent<{
      requestsInProgress: number;
    }>;
  }
}

const getRequestInterceptorEvent = (requestsInProgress: number) => {
  return new CustomEvent(REQUEST_INTERCEPTOR_EVENT_NAME, {
    bubbles: false,
    detail: {
      requestsInProgress,
    },
  });
};

export function RequestInterceptorContainer({
  children,
}: PropsWithChildren): ReactElement {
  const requestCounterRef = useRef({ count: 0 });
  const router = useRouter();

  const onRequest = useCallback(() => {
    requestCounterRef.current.count++;

    document.dispatchEvent(
      getRequestInterceptorEvent(requestCounterRef.current.count),
    );
  }, []);

  const onResponse = useCallback(() => {
    const { count } = requestCounterRef.current;
    requestCounterRef.current.count = count > 0 ? count - 1 : 0;

    document.dispatchEvent(
      getRequestInterceptorEvent(requestCounterRef.current.count),
    );
  }, []);

  useEffect(() => {
    if (isBrowser) {
      fetchInterceptors.request.push(onRequest);
      fetchInterceptors.response.push(onResponse);
      router.events.on("routeChangeStart", onRequest);
      router.events.on("routeChangeComplete", onResponse);
      router.events.on("routeChangeError", onResponse);
    }

    return () => {
      const requestIndex = fetchInterceptors.request.findIndex(onRequest);
      const responseIndex = fetchInterceptors.response.findIndex(onResponse);

      fetchInterceptors.request.splice(requestIndex, 1);
      fetchInterceptors.response.splice(responseIndex, 1);
      router.events.off("routeChangeStart", onRequest);
      router.events.off("routeChangeComplete", onResponse);
      router.events.off("routeChangeError", onResponse);
    };
  }, [onRequest, onResponse, router.events]);

  return <>{children}</>;
}
