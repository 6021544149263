import type { ImageLoaderProps } from "next/image";

export function imageServiceLoader({ src, width }: ImageLoaderProps): string {
  const img = encodeURI(src);

  // no need to update image url if it already received from image-service
  if (img.includes("image-service")) {
    return img;
  }

  return `https://image-service.onefootball.com/transform?w=${width}&dpr=2&image=${img}`;
}
