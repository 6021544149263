import { useContext } from "react";
import type { FC, ReactElement } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { PrivacyContext } from "@/modules/privacy";
import { HomeLink } from "@/components/home-link/HomeLink";
import type { FooterLink, FooterLinksList } from "@/types/footer";
import { getOneFootballTvLocale } from "@/utils/getOneFootballTvLocale";
import styles from "./Footer.module.scss";
import { FollowOnefootball } from "./follow-onefootball/FollowOnefootball";
import { getMediaLinksData } from "./follow-onefootball/mediaLinksData";
import { ONE_FOOTBALL_TV_FAQ_URL } from "@/constants/oneFootbalTvUrl";
import classNames from "classnames";
import { HorizontalSeparator } from "@/xpa-components/horizontal-separator/HorizontalSeparator";
import { YahooLogo } from "../yahoo-logo/YahooLogo";
import { envBasedConfig } from "@/constants/env-based-config.constant";

export const Footer: FC<{ countryCode: string | undefined }> = ({
  countryCode,
}) => {
  const { t } = useTranslation("web-payments");
  const gdprConsent = useContext(PrivacyContext);
  const { locale = "en" } = useRouter();
  const oneFootballTvLocale = getOneFootballTvLocale(locale);
  const quickLinks: FooterLinksList = [
    {
      urlPath: t`ONEFOOTBALL_MATCHES_PATH`,
      name: t`FOOTER_ALL_MATCHES`,
      noReferrer: false,
    },
    {
      urlPath: t`ONEFOOTBALL_ALL_TEAMS_PATH`,
      name: t`FOOTER_ALL_TEAMS`,
      noReferrer: false,
    },
    {
      urlPath: t`ONEFOOTBALL_ALL_COMPETITIONS_PATH`,
      name: t`FOOTER_ALL_COMPETITIONS`,
      noReferrer: false,
    },
  ];

  const aboutLinks: FooterLinksList = [
    { urlPath: "https://company.onefootball.com", name: t`FOOTER_COMPANY` },
    {
      urlPath: "https://company.onefootball.com/career",
      name: t`FOOTER_CAREERS`,
    },
    {
      urlPath: "https://company.onefootball.com/contact",
      name: t`FOOTER_CONTACT`,
    },
    { urlPath: t`HELP_CENTER_LINK`, name: t`FOOTER_HELPCENTER` },
  ];

  const partnerWithUsLinks: FooterLinksList = [
    {
      urlPath: "https://company.onefootball.com/sales/",
      name: t`SALES`,
    },
    {
      urlPath: "https://company.onefootball.com/clfp",
      name: t`FOOTER_CLFP`,
    },
    {
      urlPath: "https://company.onefootball.com/publisher",
      name: t`PUBLISHER`,
    },
    {
      urlPath: "https://company.onefootball.com/brand-solution",
      name: t`BRAND_SOLUTIONS`,
    },
  ];

  const legalLinks: FooterLinksList = [
    { urlPath: t`ONEFOOTBALL_IMPRINT_URL`, name: t`FOOTER_IMPRINT` },
    {
      urlPath: t`ONEFOOTBALL_PRIVACY_POLICY_URL`,
      name: t`FOOTER_PRIVACY_POLICY`,
    },
    { urlPath: t`ONEFOOTBALL_T&C_URL`, name: t`FOOTER_T&C` },
    { urlPath: t`ONEFOOTBALL_EU_DSA_INFO_URL`, name: t`FOOTER_EU_DSA` },
    { urlPath: "/en/licenses", name: t`FOOTER_LICENSES`, noReferrer: false },
  ];

  const moreOneFootballLinks: FooterLinksList = [
    {
      urlPath: "https://app.adjust.com/bw5oxho?idfa={idfa}",
      name: "App Store",
      ariaLabel: "App Store",
    },
    {
      urlPath: "https://app.adjust.com/7p22t3p?gps_adid={gps_adid}",
      name: "Google Play",
      ariaLabel: "Google Play",
    },
    {
      urlPath: "https://store.onefootball.com/",
      name: t`FOOTER_STORE`,
    },
    {
      urlPath: `${ONE_FOOTBALL_TV_FAQ_URL}/${oneFootballTvLocale}`,
      name: "TV App",
    },
  ];

  /**
   * Markup for links section - Quick Links, About, etc.
   * @param id Uniqe part of the section `id` attribute
   * @param name
   * @param linksData List of objects containing link href, text, other additional attributes
   * @returns `nav` section with header and links
   */
  const generateSectionMarkup = (
    id: string,
    name: string,
    linksData: FooterLinksList,
  ): ReactElement => {
    return (
      <nav aria-labelledby={`footer-nav-${id}-header`}>
        <h2
          className={classNames("title-7-bold", styles.footerNavHeaderText)}
          id={`footer-nav-${id}-header`}
        >
          {name}
        </h2>

        <ul className={styles.footerNavList}>
          {linksData.map((lData) => generateLink(lData))}

          {/* generating markup for `Privacy Settings` link seperately since it's behaving like
          button and its attributes mostly are different than attributes for other links */}
          {id === "legal" && generatePrivacySettingsLink()}
        </ul>
      </nav>
    );
  };

  /**
   * Generating link markup
   * @param {object} linkData
   * @returns Link markup
   */
  const generateLink = ({
    urlPath,
    name,
    noReferrer = true,
    ariaLabel = "",
  }: FooterLink): ReactElement => {
    const noReferrerAttrs = noReferrer
      ? { rel: "noreferrer", target: "_blank" }
      : {};
    const ariaLabelAttr = ariaLabel ? { ["aria-label"]: ariaLabel } : "";

    return (
      <li key={name}>
        <a
          href={urlPath}
          {...noReferrerAttrs}
          {...ariaLabelAttr}
          title={name}
          className={classNames("title-8-regular", styles.footerNavLink)}
        >
          <span className={styles.footerNavLinkText}>{name}</span>
        </a>
      </li>
    );
  };

  /**
   * Generating `Privacy Settings` link data to `Legal` links section if GDPR consent is ready
   * @returns {Array} Legal links data
   */
  const generatePrivacySettingsLink = (): ReactElement | undefined => {
    if (!gdprConsent.isAvailable) {
      return;
    }

    const linkName = t`FOOTER_PRIVACY_SETTINGS`;

    return (
      <li key={linkName}>
        <a
          onClick={gdprConsent.openPreferences}
          aria-label="Privacy Settings Button"
          role="button"
          tabIndex={0}
          title={linkName}
          className={classNames("title-8-regular", styles.footerNavLink)}
          data-testid="privacy-settings"
        >
          <span className={styles.footerNavLinkText}>{linkName}</span>
        </a>
      </li>
    );
  };

  const mediaLinksData = getMediaLinksData(t);

  return (
    <footer className={styles.footer}>
      {envBasedConfig.featureFlags.yahoo && (
        <>
          {countryCode === "US" || countryCode === "CA" ? (
            <>
              <div className={styles.yahooWrapper}>
                <div className={styles.yahooContent}>
                  <YahooLogo className="title-8-bold" />
                  <p className={classNames("text-5", styles.yahooText)}>
                    OneFootball is proud to partner with Yahoo! Sports to
                    deliver quality football content to fans in USA and Canada.
                  </p>
                </div>
              </div>
              <HorizontalSeparator className={styles.yahooSeparator} />
            </>
          ) : null}
        </>
      )}
      <div className={styles.wrapper}>
        <FollowOnefootball mediaLinksData={mediaLinksData} />
        <HorizontalSeparator />
        <section className={styles.navLinksWrapper}>
          <HomeLink />
          <div className={styles.footerNavs}>
            {generateSectionMarkup(
              "quick-links",
              t`FOOTER_QUICK_LINKS`,
              quickLinks,
            )}
            {generateSectionMarkup("about", t`FOOTER_ABOUT_US`, aboutLinks)}
            {generateSectionMarkup(
              "partner-with-us",
              t`PARTNER_WITH_US`,
              partnerWithUsLinks,
            )}
            {generateSectionMarkup("legal", t`FOOTER_LEGAL`, legalLinks)}
            {generateSectionMarkup(
              "more-onefootbal",
              t`MORE` + " OneFootball",
              moreOneFootballLinks,
            )}
          </div>
          <p className={"title-8-regular"}>
            &copy; {new Date().getFullYear()} OneFootball
          </p>
        </section>
      </div>
    </footer>
  );
};
