/*
For some reason we wanted to see our default `en` locale in the URL path. By
default, Next.js does not show the default locale in the URL. Other locales are
shown. We had dozens of unsuccessful attempts based on this suggestion:
https://github.com/vercel/next.js/discussions/18419#discussioncomment-1561577
but none of them worked.
 */

module.exports = {
  locales: ["en", "es", "pt-br", "it", "fr", "de", "id", 'ar-SA'],
  defaultLocale: "en",
  logBuild: false,
  pages: {
    "*": ["web-payments"],
  },
};
