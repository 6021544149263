import type { SupportedTypedServerParameters } from "./tracking/tracking-event";
import type { ClientParameterType } from "@motain/xpa-proto-files-web/lib/types/tracking";

const clientSideParams = new Map<
  ClientParameterType,
  SupportedTypedServerParameters
>();

export const clientSideParamsService = {
  clear: (): void => {
    clientSideParams.clear();
  },
  get: (
    type: ClientParameterType,
  ): SupportedTypedServerParameters | undefined => {
    return clientSideParams.get(type);
  },
  set: (
    type: ClientParameterType,
    value: SupportedTypedServerParameters,
  ): void => {
    clientSideParams.set(type, value);
  },
};
