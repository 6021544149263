import { useEffect, useState } from "react";
import { MEDIA_QUERY } from "@/constants/media-query.constant";

export type ScreenSize =
  | "mobile"
  | "tabletPortrait"
  | "tabletLandscape"
  | "desktop";

const screenSizes: ReadonlyArray<ScreenSize> = [
  "mobile",
  "tabletPortrait",
  "tabletLandscape",
  "desktop",
];

export type ScreenSizeState = ScreenSize | "unknown";

// provides the current screen size and notifies about subsequent changes
export function useScreenSize(listenToChanges = true): ScreenSizeState {
  const [screenSize, setScreenSize] = useState<ScreenSizeState>("unknown");

  useEffect(() => {
    const mediaQueryLists = screenSizes.map((size) => {
      return { size, mediaQueryList: window.matchMedia(MEDIA_QUERY[size]) };
    });

    // set initial state
    for (const { mediaQueryList, size } of mediaQueryLists) {
      if (mediaQueryList.matches) {
        setScreenSize(size);
        break;
      }
    }

    // No need to proceed with event listeners if we don't need updates
    if (!listenToChanges) {
      return;
    }

    // listen for media query changes
    const cleanupMediaQueryListenerFunctions = mediaQueryLists.map(
      ({ mediaQueryList, size }) => {
        function screenSizeListener(event: Event) {
          if (event instanceof MediaQueryListEvent && event.matches) {
            setScreenSize(size);
          }
        }

        mediaQueryList.addEventListener("change", screenSizeListener);

        return function cleanupMediaQueryListener() {
          mediaQueryList.removeEventListener("change", screenSizeListener);
        };
      },
    );

    return function cleanupMediaQueryListeners() {
      for (const cleanUp of cleanupMediaQueryListenerFunctions) {
        cleanUp();
      }
    };
  }, [listenToChanges]);

  return screenSize;
}
