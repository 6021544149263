import { useAdditionalDetails } from "@/utils/hooks/useAdditionalDetails";
import { useRouter } from "next/router";
import type { PropsWithChildren, ReactElement } from "react";
import { createContext, useCallback, useContext } from "react";
type UseAdditionalDetailsReturn = ReturnType<typeof useAdditionalDetails>;

interface FinishPaymentContextValue {
  finishPayment: UseAdditionalDetailsReturn[0];
  status: UseAdditionalDetailsReturn[1]["status"];
  payment: UseAdditionalDetailsReturn[1]["payment"];
}

const FinishPaymentContext = createContext<FinishPaymentContextValue>({
  finishPayment: () => Promise.resolve(),
  status: null,
  payment: null,
});

export function FinishPaymentProvider({
  children,
}: PropsWithChildren): ReactElement {
  const { push, query } = useRouter();
  const [finish, { status, payment }] = useAdditionalDetails();

  const finishPayment = useCallback(
    (...args: Parameters<typeof finish>) => {
      void push(`/payments/payment-in-progress?match=${String(query.match)}`);
      return finish(...args);
    },
    [finish, push, query],
  );

  return (
    <FinishPaymentContext.Provider value={{ finishPayment, status, payment }}>
      {children}
    </FinishPaymentContext.Provider>
  );
}

export function useFinishPayment(): FinishPaymentContextValue {
  return useContext(FinishPaymentContext);
}
