import type { ReactElement } from "react";
import React, { useState } from "react";
import type { ImageProps } from "next/image";
import NextImage from "next/image";
import { imageServiceLoader } from "@/utils/helpers/imageServiceLoader";
import { useColorSchema } from "@/context/ColorSchema";
import { isNonEmptyString } from "@/utils/type-guards/isNonEmptyStringGuard";
import { isNonNullable } from "@/types/isNonNullable";

export interface ImageWithFallbackProps extends ImageProps {
  darkModeSrc?: string;

  // React Node fallback to render when image is not loaded/broken
  fallbackImage?: React.ReactNode;

  // src to an image to render when image is not loaded/broken
  fallbackImageSrc?: string;

  // src to an image to render when image is not loaded/broken in dark mode
  fallbackImageSrcDark?: string;

  isPreloaded?: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  fallbackImage = null,
  fallbackImageSrc,
  fallbackImageSrcDark,
  isPreloaded = false,
  darkModeSrc,
  onError,
  ...restProps
}): ReactElement => {
  const { schema: theme } = useColorSchema();
  const [imageNotFound, setImageNotFound] = useState(false);

  if (src === "") {
    return <></>;
  }

  //Some XPA component may send darkModeSrc as an empty string "" when there is not darkMode image
  const adaptedSrc =
    theme === "dark" && isNonNullable(darkModeSrc) && darkModeSrc !== ""
      ? darkModeSrc
      : src;

  const adaptedFallbackSrc =
    theme === "dark" && fallbackImageSrcDark !== undefined
      ? fallbackImageSrcDark
      : fallbackImageSrc;

  const decoding = isPreloaded ? "sync" : "async";
  const loading = isPreloaded ? "eager" : "lazy";

  if (imageNotFound || adaptedSrc === "") {
    if (fallbackImage !== null) {
      return <>{fallbackImage}</>;
    }

    if (isNonEmptyString(adaptedFallbackSrc)) {
      return (
        <NextImage
          {...restProps}
          decoding={decoding}
          loading={loading}
          priority={isPreloaded}
          loader={imageServiceLoader}
          src={adaptedFallbackSrc}
        />
      );
    }
  }

  return (
    <NextImage
      {...restProps}
      decoding={decoding}
      loading={loading}
      priority={isPreloaded}
      loader={imageServiceLoader}
      src={adaptedSrc}
      onErrorCapture={(error) => {
        onError?.(error);
        setImageNotFound(true);
      }}
    />
  );
};

export { ImageWithFallback };
