import type { FC } from "react";
import React, { useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import { HomeLink } from "@/components/home-link/HomeLink";
import { Burger } from "./icons/Burger";
import { Close } from "./icons/Close";
import { MobileMenu } from "./mobile-menu/MobileMenu";
import { Snackbar } from "@/components/snackbar/Snackbar";
import { useNotification } from "@/utils/hooks/useNotification";
import { StreamName } from "@/modules/analytics";
import { SearchIcon } from "@/components/search-icon/SearchIcon";
import { ProfileIconFilled } from "@/components/profile-icon/ProfileIconFilled";
import { useAuthentication } from "@/context/Authentication";
import { ButtonLink } from "@/components/button/ButtonLink";
import { useLoginSearchParams } from "@/utils/hooks/useLoginSearchParams";
import { SettingsIcon } from "@/components/settings-icon/SettingsIcon";
import { LinksWithSubMenu, OUT_ANIMATION_DURATION } from "./Header.constants";

import styles from "./Header.module.scss";
import {
  PROFILE_ACCOUNT_ROUTE,
  PROFILE_FOLLOWING_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  PROFILE_SIGN_IN_ROUTE,
  PROFILE_SIGN_UP_ROUTE,
} from "@/constants/routes.constant";
import { useIsHeaderScrolled } from "@/utils/hooks/useIsHeaderScrolled";
import { trackNavClick } from "@/utils/trackNavClick";
import dynamic from "next/dynamic";
import LOCALE_TO_ROUTE from "../../../build-helpers/locale-to-route.constant";
import { TransitionState, useTransition } from "@/utils/hooks/useTransition";
import { Button } from "@/components/button/Button";
import Link from "next/link";
import { isNonNullable } from "@/types/isNonNullable";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { useLoadSubMenuData } from "@/components/header/useLoadSubMenuData";

const SubNav = dynamic(
  () => import("./sub-nav/SubNav").then((component) => component.SubNav),
  { ssr: false },
);

const localizedRoutes: Record<string, Record<string, string>> = LOCALE_TO_ROUTE;

export const Header: FC = () => {
  const { t } = useTranslation("web-payments");
  const { show, dismiss, open, ...snackbarProps } = useNotification();
  const { authState } = useAuthentication();
  const router = useRouter();
  const isScrolled = useIsHeaderScrolled();
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState<boolean>(false);
  const [desktopSubMenuOpen, setDesktopSubMenuOpen] = useState(false);
  const [selectedSubNavItem, setSelectedSubNavItem] =
    useState<LinksWithSubMenu | null>(null);

  const [subMenuData, loadSubMenuData] = useLoadSubMenuData();

  const [
    subNavDestroyTransition,
    startSubNavDestroyTransition,
    resetSubNavDestroyTransition,
  ] = useTransition();
  const loginSearchParams = useLoginSearchParams();
  const loginQueryString = loginSearchParams.toString();

  const handleDestroyAnimation = () => {
    void startSubNavDestroyTransition(OUT_ANIMATION_DURATION).then(() => {
      setSelectedSubNavItem(null);
    });
  };

  const handleNavItemSwitch = (subMenuLinkName?: LinksWithSubMenu) => {
    if (subMenuLinkName) {
      setSelectedSubNavItem(subMenuLinkName);
      resetSubNavDestroyTransition();
      setDesktopSubMenuOpen(true);
    } else {
      setDesktopSubMenuOpen(false);
      handleDestroyAnimation();
    }
  };

  const routerSettings =
    isNonNullable(router) &&
    isNonNullable(router.locale) &&
    router.locale === router.defaultLocale
      ? `/${router.defaultLocale}${PROFILE_SETTINGS_ROUTE}`
      : PROFILE_SETTINGS_ROUTE;

  const routerFollowing =
    isNonNullable(router) &&
    isNonNullable(router.locale) &&
    router.locale === router.defaultLocale
      ? `/${router.defaultLocale}${PROFILE_FOLLOWING_ROUTE}`
      : PROFILE_FOLLOWING_ROUTE;

  const routerSignIn =
    isNonNullable(router) &&
    isNonNullable(router.locale) &&
    router.locale === router.defaultLocale
      ? `/${router.defaultLocale}${PROFILE_SIGN_IN_ROUTE}?${loginQueryString}`
      : `${PROFILE_SIGN_IN_ROUTE}?${loginQueryString}`;

  const routerSignUp =
    isNonNullable(router) &&
    isNonNullable(router.locale) &&
    router.locale === router.defaultLocale
      ? `/${router.defaultLocale}${PROFILE_SIGN_UP_ROUTE}?${loginQueryString}`
      : `${PROFILE_SIGN_UP_ROUTE}?${loginQueryString}`;

  const isHeaderLinkActive = (link: string): boolean => {
    const locale = router.locale ?? "en";
    const localizedLink = `/${localizedRoutes[locale]?.[link] ?? ""}`;

    return router.asPath.startsWith(localizedLink);
  };

  return (
    <header
      className={classNames(styles.header, isScrolled && styles.headerScrolled)}
      onMouseLeave={() => {
        handleDestroyAnimation();
      }}
    >
      <div className={styles.mainMenu}>
        <Button
          className={classNames(
            styles.burgerButton,
            isMobileNavbarOpen && styles.mobileNavOpened,
          )}
          onClick={() => {
            // Do the call only when the user clicks on open
            if (!isMobileNavbarOpen) {
              void loadSubMenuData(LinksWithSubMenu.TEAMS);
              void loadSubMenuData(LinksWithSubMenu.COMPETITIONS);
            }
            setIsMobileNavbarOpen(!isMobileNavbarOpen);
          }}
          variant="icon-only"
        >
          {!isMobileNavbarOpen && (
            <>
              <p className="screen-reader-only">{t`OPEN_MENU`}</p>
              <Burger className={styles.menu} aria-label="hidden" />
            </>
          )}
          {isMobileNavbarOpen && (
            <>
              <p className="screen-reader-only">{t`CLOSE_MENU`}</p>
              <Close className={styles.close} aria-label="hidden" />
            </>
          )}
        </Button>
        {isMobileNavbarOpen && (
          <MobileMenu
            subMenuData={subMenuData}
            onClick={() => {
              setIsMobileNavbarOpen(false);
            }}
          />
        )}
        <HomeLink />
        <nav className={styles.headerNavWrapper}>
          <ul className={styles.headerNav}>
            <li
              className={classNames(
                styles.headerNavListItem,
                styles.desktopNavListItem,
                (isHeaderLinkActive("matches") ||
                  isHeaderLinkActive("match")) &&
                  styles.active,
              )}
            >
              <Link
                onMouseEnter={() => {
                  handleNavItemSwitch();
                }}
                onClick={() => {
                  trackNavClick(StreamName.MatchesStream);
                }}
                href={t`ONEFOOTBALL_MATCHES_PATH`}
                className={styles.headerNavLink}
              >
                {t`MATCHES`}
              </Link>
            </li>

            <li
              className={classNames(
                styles.headerNavListItem,
                styles.desktopNavListItem,
                selectedSubNavItem === LinksWithSubMenu.TEAMS && styles.hovered,
                (isHeaderLinkActive("team") ||
                  isHeaderLinkActive("all-teams")) &&
                  styles.active,
              )}
            >
              <button
                className={styles.headerNavButtonButton}
                onClick={() => {
                  handleNavItemSwitch(LinksWithSubMenu.TEAMS);
                  void loadSubMenuData(LinksWithSubMenu.TEAMS);
                }}
              >
                {t`TEAMS`}
              </button>
            </li>

            <li
              className={classNames(
                styles.headerNavListItem,
                styles.desktopNavListItem,
                selectedSubNavItem === LinksWithSubMenu.COMPETITIONS &&
                  styles.hovered,
                (isHeaderLinkActive("competition") ||
                  isHeaderLinkActive("all-competitions")) &&
                  styles.active,
              )}
            >
              <button
                className={styles.headerNavButtonButton}
                onClick={() => {
                  handleNavItemSwitch(LinksWithSubMenu.COMPETITIONS);
                  void loadSubMenuData(LinksWithSubMenu.COMPETITIONS);
                }}
              >
                {t`COMPETITIONS`}
              </button>
            </li>

            <li
              className={classNames(
                styles.headerNavListItem,
                styles.desktopNavListItem,
                isHeaderLinkActive("tv-hub") && styles.active,
              )}
              onMouseEnter={() => {
                handleNavItemSwitch();
              }}
            >
              <Link
                onClick={() => {
                  trackNavClick(StreamName.TvHub);
                }}
                href={`/${router.locale ?? "en"}/tv-hub`}
                className={styles.headerNavLink}
              >
                TV
              </Link>
            </li>

            {router.locale === "en" &&
              envBasedConfig.featureFlags.extraTime && (
                <li
                  className={classNames(
                    styles.headerNavListItem,
                    styles.desktopNavListItem,
                    isHeaderLinkActive("extra-time") && styles.active,
                  )}
                  onMouseEnter={() => {
                    handleNavItemSwitch();
                  }}
                >
                  <Link
                    onClick={() => {
                      trackNavClick(StreamName.ExtraTime);
                    }}
                    href={"/en/extra-time"}
                    className={styles.headerNavLink}
                  >
                    Extra Time
                  </Link>
                </li>
              )}

            <li
              className={classNames(
                styles.headerNavIconItem,
                styles.headerNavListItemSearch,
                isHeaderLinkActive("search") && styles.active,
              )}
            >
              <ButtonLink
                onClick={() => {
                  trackNavClick(StreamName.SearchStream);
                }}
                href={t`ONEFOOTBALL_SEARCH_PATH`}
                className={styles.headerNavLink}
                variant="icon"
              >
                <p className="screen-reader-only">{t`SEARCH`}</p>
                <SearchIcon className={styles.headerNavLinkIcon} />
              </ButtonLink>
            </li>
            <li className={styles.headerNavListDivider} />
            {authState.kind === "authenticated" && (
              <li
                className={classNames(
                  styles.headerNavIconItem,
                  router.asPath.startsWith(`/${PROFILE_ACCOUNT_ROUTE}`) &&
                    styles.active,
                )}
              >
                <ButtonLink
                  onClick={() => {
                    trackNavClick(StreamName.ProfileFollowing);
                  }}
                  isClientNavigation
                  variant="icon"
                  href={routerFollowing}
                >
                  <p className="screen-reader-only">{t`FOLLOWING`}</p>
                  <ProfileIconFilled className={styles.headerNavLinkIcon} />
                </ButtonLink>
              </li>
            )}
            {authState.kind === "unauthenticated" && (
              <>
                <li
                  className={classNames(
                    styles.headerNavIconItem,
                    styles.navListSettingsIcon,
                    router.asPath.startsWith(PROFILE_SETTINGS_ROUTE) &&
                      styles.active,
                  )}
                >
                  <ButtonLink
                    onClick={() => {
                      trackNavClick(StreamName.ProfileSettings);
                    }}
                    href={routerSettings}
                    isClientNavigation
                    variant="icon"
                  >
                    <p className="screen-reader-only">{t`SETTINGS`}</p>
                    <SettingsIcon className={styles.headerNavLinkIcon} />
                  </ButtonLink>
                </li>
                <li
                  className={classNames(
                    styles.headerNavIconItem,
                    styles.signInNavListItem,
                    router.asPath.includes(PROFILE_SIGN_IN_ROUTE) &&
                      styles.active,
                  )}
                >
                  <ButtonLink
                    href={routerSignIn}
                    isClientNavigation
                    className={styles.headerNavLink}
                    onClick={() => {
                      trackNavClick(StreamName.SignIn);
                    }}
                    variant="icon"
                  >
                    <p className="screen-reader-only">{t`LOGIN_LINK_SING_IN`}</p>
                    <ProfileIconFilled className={styles.headerNavLinkIcon} />
                  </ButtonLink>
                </li>
              </>
            )}
          </ul>
          {authState.kind === "unauthenticated" && (
            <div className={styles.headerNavButtonsContainer}>
              <ButtonLink
                href={routerSignIn}
                isClientNavigation
                variant="outline"
                className={styles.headerNavButtonLink}
                onClick={() => {
                  trackNavClick(StreamName.SignIn);
                }}
              >
                {t`LOGIN_LINK_SING_IN`}
              </ButtonLink>
              <ButtonLink
                isClientNavigation
                href={routerSignUp}
                variant="fill"
                className={styles.headerNavButtonLink}
                onClick={() => {
                  trackNavClick(StreamName.SignUp);
                }}
              >
                {t`JOIN`}
              </ButtonLink>
            </div>
          )}
        </nav>
      </div>
      {isMobileNavbarOpen && (
        <MobileMenu
          subMenuData={subMenuData}
          onClick={() => {
            setIsMobileNavbarOpen(false);
          }}
        />
      )}
      {selectedSubNavItem &&
        (desktopSubMenuOpen ||
          subNavDestroyTransition === TransitionState.InProgress) && (
          <SubNav
            {...subMenuData[selectedSubNavItem]}
            isClosing={subNavDestroyTransition === TransitionState.InProgress}
          />
        )}
      <Snackbar open={open} onClose={dismiss} {...snackbarProps} />
    </header>
  );
};
