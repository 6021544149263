import type { CookieCategory } from "../domain/cookie-category";
import type { PrivacyManager } from "../domain/privacy-manager";

interface Props {
  privacyManager: PrivacyManager;
  category: keyof CookieCategory;
}

export const isConsentGivenUseCase = ({
  privacyManager,
  category,
}: Props): boolean => {
  const preferences = privacyManager.getPreferences();

  if (preferences === undefined) {
    return false;
  }

  return privacyManager.isConsentGiven(preferences, category);
};
