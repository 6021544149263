import type { PaymentMethod } from "@/constants/paymentMethods.constant";
import { remoteLogger } from "@/utils/remoteLogger";
import { useState } from "react";
import type { Infer } from "superstruct";
import { is, number, string, type } from "superstruct";
import { envBasedConfig } from "@/constants/env-based-config.constant";

interface PaymentDetails {
  orderID: string;
  payerID: string;
  paymentId: string;
  billingToken: string | null;
  facilitatorAccessToken: string;
}

export interface AdyenAdditionalPaymentData {
  paymentData: string;
  details: PaymentDetails;
}

const amountStruct = type({
  currency: string(),
  value: number(),
});

const providerResponseStruct = type({
  amount: amountStruct,
  merchantReference: string(),
  pspReference: string(),
  resultCode: string(),
});

const additionalDetailsPaymentStruct = type({
  payment_provider_reference: string(),
  provider_response: providerResponseStruct,
  reference: string(),
  status: string(),
  type: string(),
});

export function useAdditionalDetails(): [
  (method: PaymentMethod, body: AdyenAdditionalPaymentData) => Promise<void>,
  {
    status: number | null;
    payment: Infer<typeof additionalDetailsPaymentStruct> | null;
  },
] {
  const [payment, setPayment] = useState<Infer<
    typeof additionalDetailsPaymentStruct
  > | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  async function finishPaypalPayment(data: AdyenAdditionalPaymentData) {
    return fetch(`${envBasedConfig.webPaymentsBackend}/payments/details`, {
      method: "POST",
      body: JSON.stringify({
        payment_data: data.paymentData,
        details: {
          order_id: data.details.orderID,
          payer_id: data.details.payerID,
          payment_id: data.details.paymentId,
          billing_token: data.details.billingToken,
          facilitator_access_token: data.details.facilitatorAccessToken,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async function finishPayment(
    method: PaymentMethod,
    body: AdyenAdditionalPaymentData,
  ) {
    try {
      if (method !== "paypal") {
        remoteLogger.error(
          "Only Paypal additional details endpoint is supported",
        );

        return;
      }
      const response = await finishPaypalPayment(body);

      const finishPaymentResponse: unknown = await response.json();
      if (is(finishPaymentResponse, additionalDetailsPaymentStruct)) {
        setPayment(finishPaymentResponse);
        setStatus(response.status);
      } else {
        remoteLogger.error("Finish payment has incorrect response!");
        setStatus(400);
      }
    } catch (err) {
      remoteLogger.error(err, { _method: "POST", _status_code: 500 });

      setStatus(500);
    }
  }

  return [finishPayment, { status, payment }];
}
