import { useEffect, useRef } from "react";
import { useRouter } from "next/router";

import { useColorSchema } from "@/context/ColorSchema";
import { RudderstackEventName } from "../../trackers/rudderstack/rudderstack-event-name";
import { trackingService } from "../../services/tracking/tracking-service";
import type { EntityStreamName } from "../../services/stream-name-service";

/**
 * Tracks the name and display_mode of the stream that the user visits
 */
export function useTrackingPageView(streamName: EntityStreamName): void {
  const { uiMode } = useColorSchema();
  const uIModeRef = useRef(uiMode);
  const router = useRouter();

  useEffect(() => {
    trackingService.sendEvent({
      trackers: [
        {
          kind: "rudderstack",
          extraParams: { name: RudderstackEventName.PageView },
        },
      ],
      params: { display_mode: uIModeRef.current },
    });
  }, [streamName, router.asPath]);
}
