import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { useTrackingOnUnmount } from "../useTrackingOnUnmount";
import { useTrackingXpaPageView } from "../hooks/useTrackingXpaPageView";

/**
 * Assigns a name to the XPA stream and tracks the stream visited by the user once the page is mounted.
 * For non-XPA-controlled streams use useRegisterStream()
 */
export function useRegisterXpaStream(layout: Layout): void {
  useTrackingXpaPageView(layout.trackingEvents);
  useTrackingOnUnmount(layout.trackingEvents);
}
