import { useLayoutEffect } from "react";

export const useLockBodyScroll = (lock = true): void => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (lock) {
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }

    return () => {};
  }, [lock]);
};
