import { isNullable } from "@/types/isNullable";
import type { CookieCategory } from "../../domain/cookie-category";
import { cookieCategoryCode } from "./cookie-category-code";

const COOKIE_CATEGORIES_KEY = "groups";

export const isConsentGiven = (
  consentPreferences: string,
  cookieCategory: keyof CookieCategory,
): boolean => {
  if (
    isNullable(consentPreferences) ||
    consentPreferences === "" ||
    isNullable(cookieCategory)
  ) {
    return false;
  }

  const categoryCode = cookieCategoryCode[cookieCategory];

  return (
    consentPreferences
      .split("&")
      .find((property) => property.includes(COOKIE_CATEGORIES_KEY))
      ?.split("=")
      .find((categories) => categories.includes(categoryCode))
      ?.split(",")
      .find((category) => category.includes(categoryCode))
      ?.split(":")
      .pop() === "1"
  );
};
