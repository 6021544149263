import { envBasedConfig } from "@/constants/env-based-config.constant";
import type { FetchOptions } from "@/utils/apiFetchWithDecoder";
import { apiFetchWithDecode } from "@/utils/apiFetchWithDecoder";

/**
 * The webExperienceFetch method starts the process of fetching a resource from Web experience
 * returning a promise that will be fulfilled once the response is retrieved. This method can
 * be used for XPA requests where a XPA can be retrieved, or only to obtain a status response
 * (for example follow or unfollow entities)
 * @param url web experience relative endpoint url
 * @param options object WebExperienceFetchOptions
 */
export async function webExperienceFetch<T = unknown>(
  url: string,
  options: FetchOptions<T>,
): Promise<{
  status: number;
  decoded?: T;
}> {
  const webExperienceUrl = `${envBasedConfig.webExperience}${url}`;

  return apiFetchWithDecode<T>(webExperienceUrl, options);
}
