import type { PropsWithChildren, ReactElement } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { remoteLogger } from "@/utils/remoteLogger";
import type { Infer } from "superstruct";
import { boolean, is, type } from "superstruct";
import { envBasedConfig } from "@/constants/env-based-config.constant";

const backendFeatureFlagsStruct = type({
  expired_match: boolean(),
  session_payment: boolean(),
  recurrent_payments: boolean(),
  klarna_payment: boolean(),
  card_payment: boolean(),
  paypal_payment: boolean(),
  sofort_payment: boolean(),
});

interface BackendFeatureFlagsContextValue
  extends Infer<typeof backendFeatureFlagsStruct> {}

/**
 * @description backend featureFlags are used for the checkout/match/:matchID page (payments) functionality
 * to be able control it from the server side for the faster and more controllable manipulation from the server.
 * except expired_match key, which is used in the history page for hiding watch button.
 */

const DEFAULT_FEATURE_FLAGS_VALUE: BackendFeatureFlagsContextValue = {
  expired_match: false,
  session_payment: false,
  recurrent_payments: false,
  klarna_payment: false,
  card_payment: false,
  paypal_payment: false,
  sofort_payment: false,
};

const BackendFeatureFlagsContext =
  createContext<BackendFeatureFlagsContextValue>(DEFAULT_FEATURE_FLAGS_VALUE);

export function BackendFeatureFlagsContextProvider({
  children,
}: PropsWithChildren): ReactElement {
  const [featureFlags, setFeatureFlags] = useState<
    BackendFeatureFlagsContextValue | undefined
  >(undefined);

  useEffect(() => {
    const getFeatureFlags = () => {
      void fetch(`${envBasedConfig.webPaymentsBackend}/features/config`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (is(data, backendFeatureFlagsStruct)) {
            setFeatureFlags(data);
          } else {
            remoteLogger.error(
              "Incorrect response type of the backend-feature-flags request",
            );
          }
        })
        .catch((e) => {
          remoteLogger.error(e);
        });
    };

    if (!featureFlags) {
      getFeatureFlags();
    }
  }, [featureFlags]);

  return (
    <BackendFeatureFlagsContext.Provider
      value={featureFlags ?? DEFAULT_FEATURE_FLAGS_VALUE}
    >
      {children}
    </BackendFeatureFlagsContext.Provider>
  );
}

export function useBackendFeatureFlags(): BackendFeatureFlagsContextValue {
  return useContext(BackendFeatureFlagsContext);
}
