export const AUTO = "auto";
export const ENFORCED_LIGHT = "enforced-light";
export const ENFORCED_DARK = "enforced-dark";

export type UIMode = typeof AUTO | typeof ENFORCED_LIGHT | typeof ENFORCED_DARK;

export const THEMES = [AUTO, ENFORCED_DARK, ENFORCED_LIGHT] as const;

export const BODY_CSS_CLASSNAMES: Readonly<Record<UIMode, string>> = {
  [AUTO]: "theme-auto",
  [ENFORCED_DARK]: ENFORCED_DARK,
  [ENFORCED_LIGHT]: ENFORCED_LIGHT,
};

export const TRACKING_DISPLAY_MODES: Readonly<Record<UIMode, string>> = {
  [AUTO]: "auto",
  [ENFORCED_DARK]: "dark",
  [ENFORCED_LIGHT]: "light",
};

export const UI_MODE_COOKIE_KEY = "UIMode";
