import Script from "next/script";
import type { FC } from "react";
import { useEffect } from "react";
import { isConsentGivenFor } from "@/modules/privacy";
import Head from "next/head";
import { useGPTService } from "./useGPTService";
import { remoteLogger } from "@/utils/remoteLogger";
import {
  LIMITED_ADS_SCRIPT_URL,
  STANDARD_SCRIPT_URL,
} from "../../../infra/google-publisher-tag/source-urls";
import { envBasedConfig } from "@/constants/env-based-config.constant";

if (typeof window !== "undefined") {
  /* This code is used to store commands for Google Publisher Tag while script is being loaded */
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.googletag = window.googletag ?? { cmd: [] };
}

// We don't need to bootstrap script more than once
let scriptStarted = false;

const ALTURA_HEADER_BIDDING =
  "https://altura-advertising.s3.eu-west-2.amazonaws.com/onefootball.com/Ads-main.js";

const GooglePublisherTag: FC = () => {
  const isConsentGiven = isConsentGivenFor("targeting");
  const { setPublisherProvidedId } = useGPTService();

  useEffect(() => {
    if (scriptStarted) {
      return;
    }

    googletag.cmd.push(() => {
      /* Percentage from viewport dimension value. 100 means 1 viewport.
       Mobile is preferred to have values larger, because viewport is smalled and user scrolls faster. */
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 100,
        renderMarginPercent: 50,
        mobileScaling: 2.0,
      });
    });

    scriptStarted = true;
  }, []);

  useEffect(() => {
    setPublisherProvidedId().catch((error) => {
      const errorMessage = "Failed to set publisher provided identification";
      remoteLogger.error(errorMessage, error);
    });
  }, [setPublisherProvidedId, isConsentGiven]);

  if (scriptStarted) {
    return null;
  }

  // @TODO double check if this approach is still needed (#38)
  const scriptUrl = isConsentGiven
    ? STANDARD_SCRIPT_URL
    : LIMITED_ADS_SCRIPT_URL;

  return (
    <>
      <Head>
        <link
          rel="preconnect"
          key="securepubads"
          href="https://securepubads.g.doubleclick.net"
        />
        <link
          rel="preconnect"
          key="pagead2"
          href="https://pagead2.googlesyndication.com"
        />
      </Head>
      <Script
        id="google-publisher-tag"
        strategy="afterInteractive"
        src={scriptUrl}
      />
      {envBasedConfig.featureFlags.adsAltura && (
        <Script
          id="altura-publisher-tag"
          strategy="afterInteractive"
          src={ALTURA_HEADER_BIDDING}
        />
      )}
    </>
  );
};

/**
 * @description needs to be able to use lazy loading
 */
// eslint-disable-next-line import/no-default-export
export default GooglePublisherTag;
