/**
 * Function that returns a Date in the future based on the
 * specified amount of years passed in the argument.
 * @param amountOfYears Amount of years in the future you want the generated
 * Date to be.
 */
export function getDateInXYears(amountOfYears: number): Date {
  const date = new Date();
  date.setFullYear(date.getFullYear() + amountOfYears);
  return date;
}
