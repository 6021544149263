import isBrowser from "is-in-browser";
import { remoteLogger } from "@/utils/remoteLogger";

interface FetchInterceptors {
  response: Array<() => void>;
  request: Array<() => void>;
}

export const fetchInterceptors: FetchInterceptors = {
  response: [],
  request: [],
};

if (isBrowser) {
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    let response;
    const [resource, config] = args;

    try {
      fetchInterceptors.request.forEach((interceptor) => {
        try {
          interceptor();
        } catch (e: unknown) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : `Unknown error occurred in a fetch request interceptor for ${String(
                  resource,
                )}`;
          remoteLogger.error(errorMessage);
        }
      });

      response = await originalFetch(resource, config);
    } finally {
      fetchInterceptors.response.forEach((interceptor) => {
        try {
          interceptor();
        } catch (e: unknown) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : `Unknown error occurred in a fetch response interceptor for ${String(
                  resource,
                )}`;
          remoteLogger.error(errorMessage);
        }
      });
    }

    return response;
  };
}
