import type { FC, PropsWithChildren } from "react";
import { createContext, useState } from "react";
import { noop } from "@/utils/noop";
export interface CountryCodeContextData {
  readonly countryCode: string;
  setCountryCode: (countryCode: string) => void;
}

export const CountryCodeContext = createContext<CountryCodeContextData>({
  countryCode: "",
  setCountryCode: noop,
});

export const CountryCodeProvider: FC<
  PropsWithChildren<{ InitialCountryCode: string }>
> = (props) => {
  const { children, InitialCountryCode } = props;
  const [countryCode, setCountryCode] = useState<string>(InitialCountryCode);

  return (
    <CountryCodeContext.Provider value={{ countryCode, setCountryCode }}>
      {children}
    </CountryCodeContext.Provider>
  );
};
