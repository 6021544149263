import type { XpaScreenSize } from "@/types/xpaScreenSize";

export const MEDIA_QUERY_MIN_WIDTH: Readonly<Record<XpaScreenSize, number>> = {
  mobile: 767,
  tabletPortrait: 1023,
  tabletLandscape: 1279,
  desktop: 1280,
};

export const MEDIA_QUERY: Readonly<Record<XpaScreenSize, string>> = {
  mobile: `(max-width: ${MEDIA_QUERY_MIN_WIDTH.mobile}px)`,
  tabletPortrait: `(min-width: ${
    MEDIA_QUERY_MIN_WIDTH.mobile + 1
  }px) and (max-width: ${MEDIA_QUERY_MIN_WIDTH.tabletPortrait}px)`,
  tabletLandscape: `(min-width: ${
    MEDIA_QUERY_MIN_WIDTH.tabletPortrait + 1
  }px) and (max-width: ${MEDIA_QUERY_MIN_WIDTH.tabletLandscape}px)`,
  desktop: `(min-width: ${MEDIA_QUERY_MIN_WIDTH.desktop}px)`,
};

export const BASE_TABLET_LANDSCAPE_WIDTH = 1024;

export type MediaQuery = (typeof MEDIA_QUERY)[XpaScreenSize];

export function isTabletLandscapeAndUp(width: number): boolean {
  return width >= BASE_TABLET_LANDSCAPE_WIDTH;
}
