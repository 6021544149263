import type { EnvBasedConfigApi } from "@/constants/env-based-config.constant";
import { ORIGIN } from "@/constants/origin.constant";
import {
  PAYMENTS_HISTORY,
  USERS_AUTH_API,
  WEB_PAYMENTS_BACKEND,
} from "@/constants/endpoints.constant";

export const apiConfigLocalWebExperience: EnvBasedConfigApi = {
  adyen: { env: "test", clientKey: "test_IDVTLMMA2FBZBC2LDXX6BM5WWYMYB2XU" },
  loggerUrl: "https://pappagallu-stg.onefootball.com/log",
  imageServiceUrl: "https://image-service-stg.onefootball.com",
  webExperience: "http://localhost:3000",
  paymentsHistory: `${ORIGIN.local}${PAYMENTS_HISTORY}`,
  webPaymentsBackend: `${ORIGIN.local}${WEB_PAYMENTS_BACKEND}`,
  usersAuth: `${ORIGIN.local}${USERS_AUTH_API}`,
  youboraAccountCode: "onefootballdev",
  openwebSpotId: "sp_1ffCGfIz",
  rudderstckKey: "2QI3lxVBn2RqRM5AcsZT6CMgLAZ",
  rudderstckDataPlane: "https://onefootball-dev-dataplane.rudderstack.com",
};
