import type { FooterMediaLinksList, TranslationFn } from "@/types/footer";

/**
 * Translation function is passed as an argument since `next-translate`'s
 * `useTranslation` hook can't be called outside of context provider
 * @param t {Function} `next-translate`'s translation function
 * @returns {Array} Links data - urlPath, name, etc.
 */
export function getMediaLinksData(t: TranslationFn): FooterMediaLinksList {
  return [
    {
      urlPath: "https://www.instagram.com/onefootball",
      name: "Instagram",
      handle: "@onefootball",
      iconUrl: "https://images.onefootball.com/cw/icons/instagram_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/instagram_dark.svg",
    },
    {
      urlPath: "https://www.facebook.com/OneFootball",
      name: "Facebook",
      handle: "@OneFootball.de",
      iconUrl: "https://images.onefootball.com/cw/icons/facebook_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/facebook_dark.svg",
    },
    {
      urlPath: "https://twitter.com/Onefootball",
      name: "Twitter",
      handle: "@OneFootball",
      iconUrl: "https://images.onefootball.com/cw/icons/twitter_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/twitter_dark.svg",
    },
    {
      urlPath: t`ONEFOOTBALL_YOUTUBE_URL`,
      name: "Youtube",
      handle: "@OneFootball",
      iconUrl: "https://images.onefootball.com/cw/icons/youtube_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/youtube_dark.svg",
    },
    {
      urlPath: "https://www.tiktok.com/@onefootball",
      name: "TikTok",
      handle: "@onefootball",
      iconUrl: "https://images.onefootball.com/cw/icons/tiktok_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/tiktok_dark.svg",
    },
    {
      urlPath: "https://audioboom.com/channel/onefootball",
      name: "AudioBoom",
      handle: "@OneFootball",
      iconUrl: "https://images.onefootball.com/cw/icons/audioboom_light.svg",
      darkModeIconUrl:
        "https://images.onefootball.com/cw/icons/audioboom_dark_v2.svg",
    },
  ];
}
