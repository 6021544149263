import type { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import type { ButtonVariant } from "./ButtonVariant";
import styles from "./Button.module.scss";
import { isNonNullable } from "@/types/isNonNullable";
import Link from "next/link";

export type LinkProps = {
  variant?: ButtonVariant;
  executeOnClick?: Array<() => unknown>;
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { isClientNavigation?: boolean };

export const ButtonLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const {
      className,
      variant = "ghost",
      executeOnClick,
      isClientNavigation = false,
      href,
      ...restProps
    } = props;

    const Tag = isClientNavigation ? Link : "a";

    return (
      <Tag
        className={classNames(styles.root, styles[variant], className)}
        onClick={() =>
          isNonNullable(executeOnClick) &&
          executeOnClick.map((element) => element())
        }
        href={href ?? ""}
        {...restProps}
        ref={ref}
      />
    );
  },
);

ButtonLink.displayName = "ButtonLink";
