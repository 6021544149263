import type { Infer } from "superstruct";
import { is, size, string } from "superstruct";

export const nonEmptyStringStruct = size(string(), 1, Number.MAX_SAFE_INTEGER);

export function isNonEmptyString(
  value: unknown,
): value is Infer<typeof nonEmptyStringStruct> {
  return is(value, nonEmptyStringStruct);
}
