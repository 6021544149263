import * as Inspector from "ssr-web-avo-inspector";
import { ENVIRONMENT } from "@/constants/env-based-config.constant";

const AVO_API_KEY = "IGTd8X6zGEJtbtjBokkF";
const env =
  ENVIRONMENT === "production"
    ? Inspector.AvoInspectorEnv.Prod
    : Inspector.AvoInspectorEnv.Dev;

export const avoInspector = new Inspector.AvoInspector({
  apiKey: AVO_API_KEY,
  env: env,
  version: "1.0.0",
  appName: "of-web",
});

avoInspector.enableLogging(false);
