import { useCallback, useEffect, useRef, useState } from "react";
import type { FC, PropsWithChildren } from "react";

import { PrivacyContext } from "./context";
import type { OneTrustSdk } from "../infra/one-trust/types/sdk";

const ServerSideContextProvider: FC<PropsWithChildren> = (props) => (
  <PrivacyContext.Provider value={{ isAvailable: false }}>
    {props.children}
  </PrivacyContext.Provider>
);

const ClientSideContextProvider: FC<PropsWithChildren> = (props) => {
  const [isReady, setIsReady] = useState(false);
  const maybeSdk = useRef<OneTrustSdk>();

  const handleOneTrustReady = useCallback(() => {
    if (window.OneTrust !== undefined) {
      maybeSdk.current = window.OneTrust;

      window.OneTrust.OnConsentChanged(() => {
        // @TODO we will notify whoever is interested on it. So far, it's not needed.
      });

      setIsReady(true);
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("onOneTrustReady", handleOneTrustReady);

    return () => {
      window.removeEventListener("onOneTrustReady", handleOneTrustReady);
    };
  }, [handleOneTrustReady]);

  return (
    <PrivacyContext.Provider
      value={{
        isAvailable: isReady,
        openPreferences: () => {
          maybeSdk.current?.ToggleInfoDisplay();
        },
        isAlertBoxClosed: () =>
          maybeSdk.current?.IsAlertBoxClosedAndValid() ?? false,
      }}
    >
      {props.children}
    </PrivacyContext.Provider>
  );
};

export const PrivacyContextProvider =
  typeof window === "undefined"
    ? ServerSideContextProvider
    : ClientSideContextProvider;
