import type { StreamName } from "@/modules/analytics";
import { RudderstackEventName, trackingService } from "@/modules/analytics";

export function trackNavClick(streamChosen: StreamName): void {
  trackingService.sendEvent({
    trackers: [
      {
        kind: "rudderstack",
        extraParams: { name: RudderstackEventName.NavBarInteraction },
      },
    ],
    params: { screen_accessed: streamChosen },
  });
}
