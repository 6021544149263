import { remoteLogger } from "@/utils/remoteLogger";
import { isNonNullable } from "@/types/isNonNullable";
import type { FETCH_METHOD } from "@/constants/fetch-method";
import { isNullable } from "@/types/isNullable";

export interface FetchOptions<T> {
  method: FETCH_METHOD;
  /**
   * Optional XPA Decoder use to decode the response from Web Experience
   * and obtain the object. Use only when a decoded element is required
   */
  decoder?: (input: Uint8Array, length?: number) => T;
  queryParameters?: Record<string, string>;
}

/**
 * A fetch method starts the process of fetching a resource from the given API
 * returning a promise that will be fulfilled once the response is retrieved. This method can
 * be used for XPA requests where a XPA can be retrieved, or only to obtain a status response
 * (for example follow or unfollow entities)
 * @param url absolute url path
 * @param options object FetchOptions
 */
export async function apiFetchWithDecode<T = unknown>(
  url: string,
  options: FetchOptions<T>,
): Promise<{
  status: number;
  decoded?: T;
}> {
  const statusError = { status: 500, decoded: undefined };
  const urlWithParams = generateUrl(url, options.queryParameters);

  if (isNullable(urlWithParams)) {
    return statusError;
  }

  const { status, decoded } = await fetchCall(urlWithParams, options);
  return { status, decoded };
}

const generateUrl = (
  url: string,
  queryParameters: Record<string, string> | undefined,
): URL | undefined => {
  try {
    const urlWithParams = new URL(url);

    if (isNonNullable(queryParameters)) {
      const params = queryParameters;

      Object.keys(params).forEach((key) => {
        if (key !== "method") {
          urlWithParams.searchParams.append(key, params[key] ?? "");
        }
      });
    }
    return urlWithParams;
  } catch (err) {
    remoteLogger.error("Error trying to create a new URL");
    return;
  }
};

const fetchCall = async <T>(urlWithParams: URL, options: FetchOptions<T>) => {
  try {
    const fetchFunction = await fetch(urlWithParams, {
      method: options.method,
      credentials: "include",
      headers: {
        Accept: "application/x-protobuf",
        "Content-Type": "application/x-protobuf",
      },
    });

    const buffer = await fetchFunction.arrayBuffer();

    if (options.decoder) {
      const decoded = options.decoder(new Uint8Array(buffer));
      return { status: fetchFunction.status, decoded };
    }

    return { status: fetchFunction.status, decoded: undefined };
  } catch (err) {
    remoteLogger.error(err);
    return { status: 500, decoded: undefined };
  }
};
