export const hashUserId = async (userId: string): Promise<string> => {
  const encoder = new TextEncoder();
  const encodedUserId = encoder.encode(userId);
  const userIfArrayBuffer = await crypto.subtle.digest(
    "SHA-256",
    encodedUserId,
  );
  const hashArray = Array.from(new Uint8Array(userIfArrayBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};
