import type { MatchDetails } from "@/utils/hooks/useMatch";
import type { PropsWithChildren, ReactElement } from "react";
import { createContext, useContext, useState } from "react";

interface TransactionContextValue {
  reference?: string;
  setReference: (reference?: string) => void;
  streamableOn?: Required<MatchDetails["available_on"]>;
  setStreamableOn: (streamableOn: MatchDetails["available_on"]) => void;
}

const MatchPurchaseTransaction = createContext<TransactionContextValue>({
  setReference: () => {},
  setStreamableOn: () => {},
});

export function MatchPurchaseTransactionProvider({
  children,
}: PropsWithChildren): ReactElement {
  const [reference, setReference] = useState<
    TransactionContextValue["reference"] | undefined
  >();
  const [streamableOn, setStreamableOn] =
    useState<TransactionContextValue["streamableOn"]>();

  return (
    <MatchPurchaseTransaction.Provider
      value={{
        reference,
        setReference,
        streamableOn,
        setStreamableOn,
      }}
    >
      {children}
    </MatchPurchaseTransaction.Provider>
  );
}

export const useMatchPurchaseTransaction = (): TransactionContextValue =>
  useContext(MatchPurchaseTransaction);
