import type { PrivacyManager } from "../../domain/privacy-manager";

import { getConsentCookie } from "./get-consent-cookie";
import { isConsentGiven } from "./is-consent-given";
import { setupScripts } from "./setup-scripts";

export const OneTrust: PrivacyManager = {
  setupScripts,
  getPreferences: getConsentCookie,
  isConsentGiven,
};
