import type { CountryCode } from "@/constants/headersToBeForwarded.constant";
import { RequestHeadersNamesToBeForwarded } from "@/constants/headersToBeForwarded.constant";
import type { GetServerSidePropsContext, NextPageContext } from "next";
import { isEmptyObject } from "@/types/isEmptyObject";

const normalizeHeader = (header?: string) => header?.toUpperCase().trim();

export interface NextHeaders {
  readonly countryCode?: CountryCode; // for Adyen's test environment
  readonly [RequestHeadersNamesToBeForwarded.Country]?: string; //for web experience
  readonly [RequestHeadersNamesToBeForwarded.Forwarded]?: string;
  readonly [RequestHeadersNamesToBeForwarded.Timezone]?: string;
  readonly [RequestHeadersNamesToBeForwarded.IsIOS]?: string;
  readonly [RequestHeadersNamesToBeForwarded.UserAgent]?: string;
  readonly [RequestHeadersNamesToBeForwarded.CdnMarker]?: string;
}

export function extractRequestHeaders(
  context: NextPageContext | GetServerSidePropsContext,
): NextHeaders | undefined {
  const request = context.req;

  if (request) {
    let requestHeaders: NextHeaders = {};

    // Country header
    let countryCode = request.headers[RequestHeadersNamesToBeForwarded.Country];

    if (process.env.NODE_ENV === "development") {
      countryCode = countryCode ?? "DE";
    }

    if (Array.isArray(countryCode)) {
      countryCode = normalizeHeader(countryCode[0]);
    } else {
      countryCode = normalizeHeader(countryCode);
    }

    if (countryCode !== undefined && countryCode !== "") {
      requestHeaders = {
        countryCode: countryCode,
        [RequestHeadersNamesToBeForwarded.Country]: countryCode,
        [`SSR-${RequestHeadersNamesToBeForwarded.Country}`]: countryCode,
      };
    }

    //Forwarded header
    let forwarded = request.headers[RequestHeadersNamesToBeForwarded.Forwarded];
    if (Array.isArray(forwarded)) {
      forwarded = forwarded[0];
    }

    if (forwarded !== undefined && forwarded !== "") {
      requestHeaders = {
        ...requestHeaders,
        [RequestHeadersNamesToBeForwarded.Forwarded]: forwarded,
      };
    }

    // Timezone header
    let timezone: string | Array<string> | undefined =
      request.headers[RequestHeadersNamesToBeForwarded.Timezone];

    const timezoneMapIANA: Record<string, string> = {
      "GMT-12": "Etc/GMT+12",
      "GMT-11": "Pacific/Pago_Pago",
      "GMT-10": "Pacific/Honolulu",
      "GMT-9.50": "Pacific/Marquesas",
      "GMT-9": "America/Anchorage",
      "GMT-8": "America/Los_Angeles",
      "GMT-7": "America/Denver",
      "GMT-6": "America/Chicago",
      "GMT-5": "America/New_York",
      "GMT-4": "America/Toronto",
      "GMT-3.50": "America/St_Johns",
      "GMT-3": "America/Sao_Paulo",
      "GMT-2": "America/Noronha",
      "GMT-1": "Atlantic/Azores",
      "GMT+0": "Europe/London",
      "GMT+1": "Europe/Berlin",
      "GMT+2": "Europe/Athens",
      "GMT+3": "Europe/Moscow",
      "GMT+3.50": "Asia/Tehran",
      "GMT+4": "Asia/Dubai",
      "GMT+4.50": "Asia/Kabul",
      "GMT+5": "Asia/Karachi",
      "GMT+5.50": "Asia/Kolkata",
      "GMT+5.75": "Asia/Kathmandu",
      "GMT+6": "Asia/Dhaka",
      "GMT+6.50": "Asia/Yangon",
      "GMT+7": "Asia/Bangkok",
      "GMT+8": "Asia/Singapore",
      "GMT+8.50": "Asia/Pyongyang",
      "GMT+9": "Asia/Tokyo",
      "GMT+9.50": "Australia/Adelaide",
      "GMT+10": "Australia/Sydney",
      "GMT+10.50": "Australia/Lord_Howe",
      "GMT+11": "Pacific/Guadalcanal",
      "GMT+12": "Pacific/Fiji",
      "GMT+12.75": "Pacific/Chatham",
      "GMT+13": "Pacific/Tongatapu",
      "GMT+14": "Pacific/Kiritimati",
      GMT: "Europe/London",
      PST: "America/Los_Angeles",
      EST: "America/New_York",
      "EST+1": "America/Halifax",
      CST: "America/Chicago",
      MST: "America/Denver",
    };
    // Manipulate timezone to look for datasets which contains mapped in IANA format (ONLY if CDN is Akamai)
    if (
      request.headers[RequestHeadersNamesToBeForwarded.CdnMarker] ===
        "akamai" &&
      typeof timezone === "string"
    ) {
      timezone =
        timezoneMapIANA[timezone.replace(/^["'](.+(?=["']$))["']$/, "$1")] ??
        "";
    }

    if (process.env.NODE_ENV === "development") {
      timezone = "Europe/Berlin";
    }

    if (typeof timezone === "string") {
      requestHeaders = {
        ...requestHeaders,
        [RequestHeadersNamesToBeForwarded.Timezone]: timezone,
        [`SSR-${RequestHeadersNamesToBeForwarded.Timezone}`]: timezone,
      };
    }

    //IsIOS header
    let isIos = request.headers[RequestHeadersNamesToBeForwarded.IsIOS];
    if (process.env.NODE_ENV === "development") {
      isIos = isIos ?? "false";
    }

    if (Array.isArray(isIos)) {
      isIos = isIos[0];
    }

    if (isIos !== undefined && isIos !== "") {
      requestHeaders = {
        ...requestHeaders,
        [RequestHeadersNamesToBeForwarded.IsIOS]: isIos,
      };
    }

    // User Agent header
    const userAgent =
      request.headers[RequestHeadersNamesToBeForwarded.UserAgent];
    if (userAgent !== undefined && userAgent !== "") {
      requestHeaders = {
        ...requestHeaders,
        [RequestHeadersNamesToBeForwarded.UserAgent]: userAgent,
      };
    }

    if (!isEmptyObject(requestHeaders)) {
      return requestHeaders;
    }
  }

  return undefined;
}
