import { hashUserId } from "./hashUserId";
import { useAuthentication } from "@/context/Authentication";
import { isConsentGivenFor } from "@/modules/privacy";
import { gptService } from "@/modules/ads";
import { browserCookie } from "@/utils/browserCookie";
import { isNonNullable } from "@/types/isNonNullable";
import { getDateInXYears } from "@/utils/getDateInXYears";
import { COOKIES_KEYS } from "@/constants/cookies.constants";

type UseGPTService = () => {
  setPublisherProvidedId: () => Promise<void>;
};

export const useGPTService: UseGPTService = () => {
  const isConsentGiven = isConsentGivenFor("targeting");
  const { authState } = useAuthentication();

  /**
   * Retrieves the userPPID. If the user is authenticated, the authenticated user's ID is returned.
   * If an userPPID is found in the browser's cookie, it is returned.
   * If not found,  a new random user ID will be generated using crypto.randomUUID()
   * and stored in a cookie for future use.
   */
  const getUserPPID = () => {
    // Here we check if the user is an authenticated user, if yes, we return the userId
    if (authState.kind === "authenticated") {
      return authState.user.id;
    }

    // Here we get the UserPPID from Cookies when the kind of the user is unauthenticated
    const userIdCookie = browserCookie.get(COOKIES_KEYS.UserPPID);

    // Here we check the case when we have an userId in the cookie and return it
    if (isNonNullable(userIdCookie)) {
      return userIdCookie;
    }

    // For the last, if we have an unauthenticated user, we generate a random userId
    const newUserId = crypto.randomUUID();

    // Here we are setting in the browserCookie the userPPID
    browserCookie.put({
      key: COOKIES_KEYS.UserPPID,
      value: newUserId,
      expires: getDateInXYears(1),
    });

    return newUserId;
  };

  const setPublisherProvidedId = async () => {
    if (!isConsentGiven) {
      browserCookie.remove(COOKIES_KEYS.UserPPID);
      return;
    }

    const userPPID = getUserPPID();
    const hashedPPID = await hashUserId(userPPID);
    gptService.setPublisherProvidedId(hashedPPID);
  };

  return { setPublisherProvidedId };
};
