import type { Infer } from "superstruct";
import { enums } from "superstruct";
import type { Immutable } from "@/types/immutable";

const forcedDataOptions = [
  "staging",
  "pre-prod",
  "production",
  "mock",
  "local-web-experience",
  "https-local-web-experience",
] as const;

export const forcedDataOptionsStruct = enums(forcedDataOptions);

export type ForcedDataOption = Immutable<Infer<typeof forcedDataOptionsStruct>>;

export const FORCED_DATA_OPTION_COOKIE_NAME = "do";
