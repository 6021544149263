import type { FC } from "react";
import { useCallback, useEffect } from "react";
import classNames from "classnames";
import type { GoogleAdsPlaceholder } from "@motain/xpa-proto-files-web/lib/types/google_ads_placeholder";

import { isNullable } from "@/types/isNullable";
import { isNonNullable } from "@/types/isNonNullable";

import GooglePublisherTag from "../../components/google-publisher-tag/GooglePublisherTag";
import { gptService } from "../../services/gpt-service";
import { convertProtoSizesToGPTSizeMapping } from "./convert-proto-sizes-to-gpt-size-mapping";
import { getMinHeightPerViewport } from "./get-viewports-min-height";
import styles from "./XpaGoogleAdsPlaceholder.module.scss";
import { envBasedConfig } from "@/constants/env-based-config.constant";

export const XpaGoogleAdsPlaceholder: FC<GoogleAdsPlaceholder> = ({
  adUnitPath,
  divId,
  generalSizes,
  targetingKeywords,
  hasBackground,
}) => {
  const renderAds = useCallback(() => {
    if (isNullable(generalSizes)) {
      return;
    }

    gptService.addSlot(
      adUnitPath,
      divId,
      convertProtoSizesToGPTSizeMapping(generalSizes),
      targetingKeywords,
    );

    if (!envBasedConfig.featureFlags.adsAltura) {
      gptService.display(divId);
    }
  }, [adUnitPath, divId, generalSizes, targetingKeywords]);

  const adSlot = useCallback(
    (node: HTMLElement | null) => {
      if (isNonNullable(node)) {
        renderAds();
      }
    },
    [renderAds],
  );

  useEffect(() => {
    return () => {
      gptService.destroySlot(divId);
    };
  }, [divId]);

  const minHeights = getMinHeightPerViewport(generalSizes);

  return (
    <div>
      <GooglePublisherTag />
      <div
        style={{
          "--mobile-ad-slot-height": `${minHeights.mobile}px`,
          "--tablet-ad-slot-height": `${minHeights.tablet}px`,
          "--desktop-ad-slot-height": `${minHeights.desktop}px`,
        }}
        className={classNames(
          styles.adsWrapper,
          hasBackground && styles.adsWrapperWithBg,
        )}
      >
        <div id={divId} ref={adSlot}></div>
      </div>
    </div>
  );
};
