import type { EnvBasedConfigApi } from "@/constants/env-based-config.constant";
import { ORIGIN } from "@/constants/origin.constant";
import {
  PAYMENTS_HISTORY,
  USERS_AUTH_API,
  WEB_EXPERIENCE,
  WEB_PAYMENTS_BACKEND,
} from "@/constants/endpoints.constant";

export const apiConfigProd: EnvBasedConfigApi = {
  adyen: { env: "live", clientKey: "live_7QBX26OTQNE73IL45HZ4PII5QEODBDAF" },
  loggerUrl: "https://pappagallu.onefootball.com/log",
  imageServiceUrl: "https://image-service.onefootball.com",
  webExperience: `${ORIGIN.production}${WEB_EXPERIENCE}`,
  paymentsHistory: `${ORIGIN.production}${PAYMENTS_HISTORY}`,
  webPaymentsBackend: `${ORIGIN.production}${WEB_PAYMENTS_BACKEND}`,
  usersAuth: `${ORIGIN.production}${USERS_AUTH_API}`,
  youboraAccountCode: "onefootball",
  openwebSpotId: "sp_mWKP5JAD",
  rudderstckKey: "2PdtNTP7dtE0CErQeCwdtdYhX7l",
  rudderstckDataPlane: "https://onefootbalnurg.dataplane.rudderstack.com",
};
