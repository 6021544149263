import { ENVIRONMENT } from "@/constants/env-based-config.constant";

export const TOKEN_NAME_FROM_EMAIL_AUTH_PROVIDER = "firebase_token" as const;
export const TOKEN_NAME_FOR_USERS_AUTH_API = "firebase_jwt_token" as const;

export type EmailAuthError =
  | "email-already-in-use"
  | "wrong-password"
  | "user-not-found"
  | "unknown";

const firebaseErrorToGenericError: Readonly<Record<string, EmailAuthError>> = {
  "Firebase: Error (auth/email-already-in-use).": "email-already-in-use",
  "Firebase: Error (auth/wrong-password).": "wrong-password",
  "Firebase: Error (auth/user-not-found).": "user-not-found",
};

export interface EmailAuthenticatedUser
  extends Readonly<{
    token: string;
  }> {}

export interface EmailAuthService {
  signUp: (
    credentials: Readonly<Record<"email" | "password", string>>,
  ) => Promise<EmailAuthenticatedUser>;

  signIn: (
    credentials: Readonly<Record<"email" | "password", string>>,
  ) => Promise<EmailAuthenticatedUser>;

  signOut: () => Promise<void>;

  parseError: (error: unknown) => EmailAuthError;
}

/**
 * All direct operations on the Firebase Auth API should be done through this.
 * Ideally, we should be able to easily swap it by another provider or even
 * by network request to OneFootball APIs.
 * Firebase is lazy-loaded, so we don't need to worry about this bundle size at
 * the bootstrap time.
 */

export const emailAuthService: EmailAuthService = {
  async signUp({ email, password }) {
    const { auth, createUserWithEmailAndPassword } =
      await lazyInitFirebaseAuth();

    const firebaseUser = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    return {
      token: await firebaseUser.user.getIdToken(),
    };
  },

  async signIn({ email, password }) {
    const { auth, signInWithEmailAndPassword } = await lazyInitFirebaseAuth();

    const firebaseUser = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );

    return {
      token: await firebaseUser.user.getIdToken(),
    };
  },

  async signOut() {
    const { auth } = await lazyInitFirebaseAuth();
    await auth.signOut();
  },

  parseError(err) {
    return err instanceof Error
      ? firebaseErrorToGenericError[err.message] ?? "unknown"
      : "unknown";
  },
};

const env = ENVIRONMENT === "production" ? "production" : "staging";

/**
 * It's fine to invoke this function multiple times, because:
 * - dynamic imports are cached
 * - Firebase caches the initialization `initializeApp()` call
 */
async function lazyInitFirebaseAuth() {
  const [
    { initializeApp },
    { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword },
  ] = await Promise.all([import("firebase/app"), import("firebase/auth")]);
  const app = initializeApp(firebaseConfig[env]);
  return {
    auth: getAuth(app),
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
  };
}

// these are public credentials, and it is ok to keep them in the code.
const firebaseConfig = {
  production: {
    apiKey: "AIzaSyB-2eXq_HiF3zifHUjFAZULi4F11nQvtJ8",
    authDomain: "api-project-693939097463.firebaseapp.com",
    projectId: "api-project-693939097463",
    appId: "1:693939097463:web:537f76bd837660f7905b72",
  },
  // staging login isn't working at all anymore, so it's better to use production for now
  staging: {
    apiKey: "AIzaSyDsJ3OFMYDmtG7IatcGmEiND9Ii7_XQozo",
    authDomain: "onefootball-api-staging.firebaseapp.com",
    projectId: "onefootball-api-staging",
    appId: "1:215065232580:web:615abc15558cb54fa0b591",
  },
} as const;
