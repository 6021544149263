import type { Infer } from "superstruct";
import { is, type } from "superstruct";
import { nonEmptyStringStruct } from "@/utils/type-guards/isNonEmptyStringGuard";

const appleIdTokenResponseStruct = type({ token: nonEmptyStringStruct });

export type AppleIdTokenResponse = Infer<typeof appleIdTokenResponseStruct>;

export function isAppleIdTokenResponse(
  value: unknown,
): value is AppleIdTokenResponse {
  return is(value, appleIdTokenResponseStruct);
}
