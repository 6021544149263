import type { FC } from "react";

export const ProfileIconFilled: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={className}>
      <path
        d="M7 15c-2 0-4 2-4 3l-1 4a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1l-1-4c0-1-2-3-4-3H7Zm5-14a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
        fill="currentColor"
      />
    </svg>
  );
};
