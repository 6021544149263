import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  PropsWithChildren,
} from "react";
import classNames from "classnames";
import type { ButtonVariant } from "./ButtonVariant";
import styles from "./Button.module.scss";

type ButtonProps = { variant?: ButtonVariant } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const { className, variant = "ghost", ...restProps } = props;

  return (
    <button
      className={classNames(className, styles.root, styles[variant])}
      {...restProps}
    />
  );
};
