import { useEffect, useState } from "react";
import type { ScreenSizeState } from "./useScreenSize";
import { useScreenSize } from "./useScreenSize";

const screenSizeToIsSticky: Readonly<Record<ScreenSizeState, boolean>> = {
  unknown: false,
  mobile: false,
  tabletPortrait: true,
  tabletLandscape: true,
  desktop: true,
};

export const useIsHeaderScrolled = (): boolean => {
  const screenSize = useScreenSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const isSticky = screenSizeToIsSticky[screenSize];

  useEffect(() => {
    if (!isSticky) {
      return;
    }

    function scrollListener() {
      setIsScrolled(window.scrollY > 10);
    }

    window.addEventListener("scroll", scrollListener);

    return function removeScrollListener() {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [isSticky]);

  return isScrolled;
};
