import type { SnackbarProps } from "@/components/snackbar/Snackbar";
import { SeverityType } from "@/components/snackbar/Snackbar";
import { useCallback, useState } from "react";

export type NotificationProps = SnackbarProps & {
  /**
   * Show snackbar.
   */
  show: (props: Partial<SnackbarProps>) => void;

  /**
   * Hide snackbar.
   */
  dismiss: () => void;
};

export function useNotification(
  defaultProps?: SnackbarProps,
): NotificationProps {
  const [open, setOpen] = useState<boolean>(defaultProps?.open ?? false);
  const [dismissible, setDismissible] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(
    defaultProps?.message,
  );
  const [type, setType] = useState<SeverityType>(
    defaultProps?.type ?? SeverityType.unknown,
  );
  const [autoHideDuration, setAutoHideDuration] = useState<number>(
    defaultProps?.autoHideDuration ?? 3_000,
  );
  const [onClose, setOnClose] = useState<SnackbarProps["onClose"]>(
    () => defaultProps?.onClose,
  );

  const dismiss = () => {
    setOpen(false);
    onClose?.();
  };

  const show = useCallback((props: Partial<SnackbarProps>): void => {
    setOpen(true);
    setMessage(props.message);
    setDismissible(props.dismissible ?? false);
    setOnClose(() => props.onClose);
    if (props.type !== undefined) {
      setType(props.type);
    }

    if (props.autoHideDuration !== undefined) {
      setAutoHideDuration(props.autoHideDuration);
    }
  }, []);

  return {
    open,
    message,
    type,
    dismissible,
    autoHideDuration,
    show,
    dismiss,
  };
}
