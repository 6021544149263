import type { UIMode } from "@/constants/themes.constant";
import { THEMES, UI_MODE_COOKIE_KEY } from "@/constants/themes.constant";
import { enums, validate } from "superstruct";

export function getUiModeFromCookies(
  cookies: Record<string, string>,
): UIMode | undefined {
  const themes = enums<UIMode, typeof THEMES>(THEMES);

  const [, uiMode] = validate(cookies[UI_MODE_COOKIE_KEY], themes);

  return uiMode;
}
