export const PROFILE_SETTINGS_ROUTE = "/payments/profile/settings";
export const PROFILE_FOLLOWING_ROUTE = "/payments/profile/following";
export const ONBOARDING_FAVOURITES_COUNTRIES =
  "/onboarding/favourites/teams/countries";
export const ONBOARDING_FAVOURITES_NATIONAL_TEAM =
  "/onboarding/favourites/teams/national";
export const PROFILE_ACCOUNT_ROUTE = "/payments/profile/account";
export const PROFILE_ACCOUNT_CONSENT_ROUTE =
  "/payments/profile/account/consent";
export const PROFILE_SIGN_IN_ROUTE = "/payments/sign-in";
export const PROFILE_SIGN_IN_WITH_EMAIL_ROUTE = "/payments/sign-in/email";
export const PROFILE_SIGN_UP_ROUTE = "/payments/sign-up";
export const PROFILE_SIGN_UP_WITH_EMAIL_ROUTE = "/payments/sign-up/email";
export const PROFILE_VERIFY_EMAIL =
  "/payments/verify-email?redirect=payments/profile/account";
export const PROFILE_BOOKMARKS_ROUTE = "/payments/profile/bookmarks";
