import { useCallback, useState } from "react";
import {
  LinksWithSubMenu,
  SERVER_ERROR_CODE,
} from "@/components/header/Header.constants";
import {
  HEADER_SUB_NAV_COMPETITION,
  HEADER_SUB_NAV_TEAM,
} from "@/constants/endpoints.constant";
import { lazyInitSubNavRuntime } from "@/components/header/lazyInitSubNavRuntime";
import { webExperienceFetch } from "@/utils/webExperienceFetch";
import type { NavigationSections } from "@motain/xpa-proto-files-web/lib/types/navigation_bar";
import { FETCH_METHOD } from "@/constants/fetch-method";
import { SeverityType } from "@/components/snackbar/Snackbar";
import type { SubNavType } from "@/components/header/Header.types";
import useTranslation from "next-translate/useTranslation";
import { useNotification } from "@/utils/hooks/useNotification";
import { useRouter } from "next/router";
import { remoteLogger } from "@/utils/remoteLogger";
import { isNullable } from "@/types/isNullable";
export const useLoadSubMenuData = (): [
  SubNavType,
  (subMenuLink: LinksWithSubMenu) => Promise<unknown>,
] => {
  const { t } = useTranslation("web-payments");
  const { show } = useNotification();
  const { locale } = useRouter();

  const [subMenuData, setSubMenuData] = useState<SubNavType>({
    teams: undefined,
    competitions: undefined,
  });

  const loadSubMenuData = useCallback(
    async (subMenuLink: LinksWithSubMenu) => {
      const url =
        subMenuLink === LinksWithSubMenu.TEAMS
          ? HEADER_SUB_NAV_TEAM(locale ?? "en")
          : HEADER_SUB_NAV_COMPETITION(locale ?? "en");

      try {
        const decoder = await lazyInitSubNavRuntime();

        const response = await webExperienceFetch<NavigationSections>(url, {
          method: FETCH_METHOD.GET,
          decoder: decoder,
        });

        if (
          response.status === SERVER_ERROR_CODE ||
          isNullable(response.decoded)
        ) {
          show({
            type: SeverityType.error,
            message: t`GENERIC_ERROR_MESSAGE`,
          });
        }

        setSubMenuData((prevState) => ({
          ...prevState,
          [subMenuLink]: response.decoded,
        }));
      } catch (error) {
        remoteLogger.error("Error fetching loadSubMenuData", error);
      }
    },
    [locale, show, t],
  );

  return [subMenuData, loadSubMenuData];
};
