import { useEffect } from "react";
import { useRouter } from "next/router";
import { remoteLogger } from "@/utils/remoteLogger";
import { isNullable } from "@/types/isNullable";

interface History {
  href: string;
  hostname: string;
}
interface Store {
  current?: History;
  previous?: History;
}

export const store: Store = {};

export const history = {
  get referrer(): string {
    return store.previous?.href ?? "$direct";
  },
  get referring_domain(): string {
    return store.previous?.hostname ?? "";
  },
};

const previousFromDocument = (): History => {
  if (document.referrer === "") {
    return {
      href: "$direct",
      hostname: "",
    };
  }

  const { href, hostname } = new URL(document.referrer);

  return {
    href,
    hostname,
  };
};

export const useReferrer = (): void => {
  const router = useRouter();

  useEffect(() => {
    try {
      if (isNullable(store.previous)) {
        store.previous = previousFromDocument();
      } else {
        store.previous = store.current;
      }

      store.current = {
        href: window.location.href,
        hostname: window.location.hostname,
      };
    } catch (error) {
      remoteLogger.error(error);
    }
  }, [router.asPath]);
};
