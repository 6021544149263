import type { UIMode } from "@/constants/themes.constant";
import {
  AUTO,
  BODY_CSS_CLASSNAMES,
  ENFORCED_DARK,
  ENFORCED_LIGHT,
  UI_MODE_COOKIE_KEY,
} from "@/constants/themes.constant";
import type { FC, PropsWithChildren } from "react";
import React, { useEffect, useRef, useState } from "react";
import { noop } from "@/utils/noop";
import { browserCookie } from "@/utils/browserCookie";
import { getDateInXYears } from "@/utils/getDateInXYears";
import Head from "next/head";
import {
  DARK_MODE_MEDIA_QUERY,
  LIGHT_MODE_MEDIA_QUERY,
} from "@/constants/color-theme-media-queris.constant";

export type ColorSchema = "light" | "dark";

export interface ColorSchemaContext {
  readonly schema: ColorSchema;
  /**
   * We cannot detect user OS preferences during SSR. That's why for "auto" mode we fall back to "light" schema.
   * But this schema might not be final, if user has OS preferences for dark mode.
   * Because if this, we cannot say that schema will not be changed before we query it in useEffect.
   * If you need to rely only on stable schema, use this flag or useStableSchema hook.
   * Other uiModes will have this flag set to true during both SSR and CSR.
   * */
  readonly schemaIsStable: boolean;
  readonly uiMode: UIMode;
  setUiMode: (uiMode: UIMode | ((uiMode: UIMode) => UIMode)) => unknown;
}

const ColorSchemaCtx = React.createContext<ColorSchemaContext>({
  schema: "light",
  schemaIsStable: false,
  uiMode: "auto",
  setUiMode: noop,
});

// we just parse the cookie value during SSR
const ThemeProviderNode: FC<PropsWithChildren<{ initialUiMode: UIMode }>> = (
  props,
) => {
  return (
    <ColorSchemaCtx.Provider
      value={{
        schema: uiModeToColorSchema(props.initialUiMode),
        schemaIsStable: props.initialUiMode !== "auto",
        uiMode: props.initialUiMode,
        setUiMode: noop,
      }}
    >
      {props.children}
    </ColorSchemaCtx.Provider>
  );
};

/**
 * We use the cookie value as an initial value. Then we allow the user to change
 * the uiMode setting. In "auto" uiMode we listen to the OS theme changes.
 * This may produce incorrect initial value for "auto" mode during SSR, because by default we assume "light" theme.
 * It you need to be 100% sure what schema user has, use schemaIsStable flag or useStableSchema hook.
 */
const ThemeProviderBrowser: FC<PropsWithChildren<{ initialUiMode: UIMode }>> = (
  props,
) => {
  const darkQuery = useRef(window.matchMedia(DARK_MODE_MEDIA_QUERY));
  const lightQuery = useRef(window.matchMedia(LIGHT_MODE_MEDIA_QUERY));

  const [uiMode, setUiMode] = useState<UIMode>(props.initialUiMode);

  const [schema, setSchema] = useState<ColorSchema>(
    uiModeToColorSchema(props.initialUiMode),
  );
  const [schemaIsStable, setSchemaIsStable] = useState(
    props.initialUiMode !== "auto",
  );

  useEffect(() => {
    setSchema(
      uiModeToColorSchema(
        props.initialUiMode,
        darkQuery.current.matches ? "dark" : "light",
      ),
    );

    if (!schemaIsStable) {
      setSchemaIsStable(true);
    }
  }, [props.initialUiMode, schemaIsStable]);

  useEffect(() => {
    // toggle body classnames
    const { classList } = window.document.body;
    switch (uiMode) {
      case AUTO: {
        classList.add(BODY_CSS_CLASSNAMES[AUTO]);
        classList.remove(BODY_CSS_CLASSNAMES[ENFORCED_LIGHT]);
        classList.remove(BODY_CSS_CLASSNAMES[ENFORCED_DARK]);
        break;
      }

      case ENFORCED_LIGHT: {
        classList.add(BODY_CSS_CLASSNAMES[ENFORCED_LIGHT]);
        classList.remove(BODY_CSS_CLASSNAMES[AUTO]);
        classList.remove(BODY_CSS_CLASSNAMES[ENFORCED_DARK]);
        break;
      }

      case ENFORCED_DARK: {
        classList.add(BODY_CSS_CLASSNAMES[ENFORCED_DARK]);
        classList.remove(BODY_CSS_CLASSNAMES[AUTO]);
        classList.remove(BODY_CSS_CLASSNAMES[ENFORCED_LIGHT]);
        break;
      }
    }

    // persist new setting in cookie
    browserCookie.put({
      key: UI_MODE_COOKIE_KEY,
      value: uiMode,
      expires: getDateInXYears(5),
    });

    // update schema state for enforced uiModes
    if (uiMode !== "auto") {
      setSchema(uiModeToColorSchema(uiMode));
      return;
    }

    const dark = darkQuery.current;
    const light = lightQuery.current;

    // set schema for "auto" uiMode base on the OS theme current value
    setSchema(dark.matches ? "dark" : "light");

    // listen to OS theme changes for "auto" uiMode
    function darkListener({ matches }: MediaQueryListEvent) {
      if (matches) {
        setSchema("dark");
      }
    }

    function lightListener({ matches }: MediaQueryListEvent) {
      if (matches) {
        setSchema("light");
      }
    }

    dark.addEventListener("change", darkListener);
    light.addEventListener("change", lightListener);

    return () => {
      dark.removeEventListener("change", darkListener);
      light.removeEventListener("change", lightListener);
    };
  }, [uiMode]);

  return (
    <ColorSchemaCtx.Provider
      value={{ schema, setUiMode, uiMode, schemaIsStable }}
    >
      <Head>
        <meta
          content={schema === "light" ? "#fff" : "#000"}
          name="theme-color"
        />
        <meta
          content={schema === "light" ? "light dark" : "dark light"}
          name="color-scheme"
        />
      </Head>

      {props.children}
    </ColorSchemaCtx.Provider>
  );
};

export const ColorSchemaProvider =
  typeof window === "undefined" ? ThemeProviderNode : ThemeProviderBrowser;

export function useColorSchema(): ColorSchemaContext {
  return React.useContext(ColorSchemaCtx);
}

function uiModeToColorSchema(
  uiMode: UIMode,
  preferredColorSchema: ColorSchema = "light",
): ColorSchema {
  switch (uiMode) {
    case "enforced-dark":
      return "dark";
    case "enforced-light":
      return "light";
    case "auto":
      return preferredColorSchema;
  }
}
