/* eslint-disable no-console */
import { isError } from "@/utils/type-guards/errorTypeGuard";
import isBrowser, { isNode } from "is-in-browser";
import { ENVIRONMENT } from "@/constants/env-based-config.constant";
import type { Nullable } from "@/types/nullable";
import { is, literal, optional, union } from "superstruct";

type LogLevel = "info" | "warn" | "error" | "debug";

const SYSTEM_LOG_LEVEL: Nullable<LogLevel> = is(
  process.env.LOG_LEVEL,
  optional(
    union([
      literal("debug"),
      literal("info"),
      literal("warn"),
      literal("error"),
    ]),
  ),
)
  ? process.env.LOG_LEVEL
  : undefined;

export interface LogMessage {
  _level: LogLevel;
  _application: string;
  _message: string;
  _time: string;
  _tracking_id: string;
  _environment?: string | null;
  _error: string | null;
  _host?: string | null;
  _url?: string | null;
  _user_agent?: string | null;
  _revision?: string | null;
  _version?: string;
  data?: unknown;
}

const APPLICATION = "consumer-web-v3";

export function log(message: string): LogMessage | undefined {
  if (
    SYSTEM_LOG_LEVEL === "warn" ||
    SYSTEM_LOG_LEVEL === "error" ||
    SYSTEM_LOG_LEVEL === "debug"
  ) {
    return undefined;
  }
  const payload = format("info", message);

  console.log(JSON.stringify(payload));

  return payload;
}

export function debug(message: string): LogMessage | undefined {
  if (
    SYSTEM_LOG_LEVEL === "warn" ||
    SYSTEM_LOG_LEVEL === "error" ||
    SYSTEM_LOG_LEVEL === "info"
  ) {
    return undefined;
  }
  const payload = format("debug", message);

  console.debug(JSON.stringify(payload));

  return payload;
}

export function warn(message: string): LogMessage | undefined {
  if (
    SYSTEM_LOG_LEVEL === "debug" ||
    SYSTEM_LOG_LEVEL === "error" ||
    SYSTEM_LOG_LEVEL === "info"
  ) {
    return undefined;
  }
  const payload = format("warn", message);

  console.warn(JSON.stringify(payload));

  return payload;
}

export function error(err: unknown, data?: unknown): LogMessage | undefined {
  if (
    SYSTEM_LOG_LEVEL === "warn" ||
    SYSTEM_LOG_LEVEL === "debug" ||
    SYSTEM_LOG_LEVEL === "info"
  ) {
    return undefined;
  }
  const message = isError(err) ? err.message : JSON.stringify(err);
  const payload = format("error", message, message, data);

  console.error(JSON.stringify(payload));

  return payload;
}

function format(
  level: LogLevel,
  message: string,
  err: null | string = null,
  data?: unknown,
): LogMessage {
  let payload: Partial<LogMessage> = {};

  const versionEnv = ENVIRONMENT === "production" ? `prod` : "staging";
  if (isBrowser) {
    payload = {
      _url: window.location.href,
      _user_agent: navigator.userAgent,
      _host: window.location.host,
      _revision: window.__NEXT_DATA__.buildId,
      _version: `${window.__NEXT_DATA__.buildId}-${versionEnv}`,
    };
  }

  if (isNode) {
    payload = {
      _revision: process.env.BUILD_ID,
      _version: `${process.env.BUILD_ID ?? "n.a."}-${versionEnv}`,
      _environment: ENVIRONMENT,
    };
  }

  if (typeof data === "object") {
    payload = { ...payload, ...data };
  } else {
    payload = { ...payload, data };
  }

  return {
    _level: level,
    _message: message,
    _error: err,
    _tracking_id: Math.random().toString(36).substring(2),
    _application: APPLICATION,
    _time: new Date().toISOString(),
    ...payload,
  };
}
