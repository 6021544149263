import type { EntityStreamName } from "../../services/stream-name-service";
import { useStreamNameForThisPage } from "../../services/stream-name-service";
import { useReferrer } from "../../useReferrer";
import { useTrackingPageView } from "./useTrackingPageView";

/**
 * Assigns a name to the non-XPA stream and tracks the stream visited by the user once the page is mounted.
 * For XPA-controlled streams use useRegisterXpaStream()
 */
export function useRegisterStream(name: EntityStreamName): void {
  useReferrer();
  useStreamNameForThisPage(name);
  useTrackingPageView(name);
}
