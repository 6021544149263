import type { Environment } from "./env-based-config.constant";

// all possible origins
export const ORIGIN: Readonly<
  Record<Environment, `https://${string}onefootball.com`>
> = {
  local: "https://local.onefootball.com",
  staging: "https://staging.onefootball.com",
  preprod: "https://preprod.onefootball.com",
  production: "https://onefootball.com",
};
