/**
 * List of shared search parameters (so that we don't rely on magic strings)
 */
export const enum SearchParam {
  /**
   * Tells /missing-consent page that this is an Apple sign-in flow in progress.
   * There should be a cookie with Apple's `id_token` in it and we should use it
   * to detect if the consent was given or not.
   */
  MissingConsentAppleSignUp = "apple-sign-up",

  /**
   * Boolean value that tells if the user has consented to the privacy policy.
   */
  LoginConsentSeen = "consent_seen",

  /**
   * Boolean value that tells if the user has subscribed to the marketing spam.
   */
  LoginSubscribed = "subscribed",

  /**
   * A generic name for cases when we have redirect the user to the desired
   * page from an intermediary pages
   */
  Redirect = "redirect",

  /**
   * String value that tells from which location is the user directed to login screen
   */
  Origin = "origin",
}
