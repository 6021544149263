import type {
  GoogleAdsPlaceholder_GeneralSize,
  GoogleAdsPlaceholder_GeneralSizes,
} from "@motain/xpa-proto-files-web/lib/types/google_ads_placeholder";
import { isNullable } from "@/types/isNullable";

interface minHeightViewports {
  mobile: number;
  tablet: number;
  desktop: number;
}
export const getMinHeightPerViewport = (
  sizes: GoogleAdsPlaceholder_GeneralSizes | undefined,
): minHeightViewports => {
  if (isNullable(sizes)) {
    const defaultValue = 0;
    return {
      desktop: defaultValue,
      tablet: defaultValue,
      mobile: defaultValue,
    };
  }

  return {
    desktop: getMinHeight(sizes.desktop),
    tablet: getMinHeight(sizes.tablet),
    mobile: getMinHeight(sizes.mobile),
  };
};

const getMinHeight = (
  sizes: Array<GoogleAdsPlaceholder_GeneralSize>,
): number => {
  return Math.min(...sizes.map((_sizes) => _sizes.minHeight));
};
