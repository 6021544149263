import { createContext } from "react";

interface NotAvailable {
  isAvailable: false;
}
interface Available {
  isAvailable: true;
  openPreferences: () => void;
  isAlertBoxClosed: () => boolean;
}
type PrivacyContextType = NotAvailable | Available;

export const privacyPreferencesManagerNotAvailable: NotAvailable = {
  isAvailable: false,
};

export const PrivacyContext = createContext<PrivacyContextType>(
  privacyPreferencesManagerNotAvailable,
);
