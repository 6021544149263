import type { TrackingEvent } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { clientSideParamsService } from "../services/client-side-params-service";
import { trackingService } from "../services/tracking/tracking-service";
import {
  ClientParameterType,
  EventType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import { useEffect, useState } from "react";
import type { SupportedTypedServerParameters } from "../services/tracking/tracking-event";

const NUMBER_OF_MILLISECONDS_IN_A_SECOND = 1000;
const pageVisitDuration = (startTime: Date): number => {
  const durationInFloat =
    (new Date().getTime() - startTime.getTime()) /
    NUMBER_OF_MILLISECONDS_IN_A_SECOND;
  return Math.floor(durationInFloat); //the return must be an integer
};

const trackOnDestroy = (
  trackingEvent: Array<TrackingEvent>,
  initialDate: Date,
) => {
  trackingEvent.forEach((event) => {
    if (event.type === EventType.EVENT_INVISIBLE) {
      const clientParams: Record<string, SupportedTypedServerParameters> =
        event.clientParameterRequests.reduce(
          (params: Record<string, SupportedTypedServerParameters>, param) => {
            switch (param.type) {
              case ClientParameterType.PARAMETER_VISIBLE_DURATION_SECONDS: {
                params[param.key] = pageVisitDuration(initialDate);
                return params;
              }

              case ClientParameterType.PARAMETER_SCROLL_TO_BOTTOM: {
                params[param.key] =
                  clientSideParamsService.get(
                    ClientParameterType.PARAMETER_SCROLL_TO_BOTTOM,
                  ) ?? false;

                return params;
              }

              case ClientParameterType.PARAMETER_SCROLL_TO_END_OF_SECTION: {
                params[param.key] =
                  clientSideParamsService.get(
                    ClientParameterType.PARAMETER_SCROLL_TO_END_OF_SECTION,
                  ) ?? false;

                return params;
              }

              default: {
                return params;
              }
            }
          },
          {},
        );

      const composedEvent = {
        ...clientParams,
      };
      trackingService.sendSingleXpaTracking(event, composedEvent);
    }
  });
};

export function useTrackingOnUnmount(
  trackingEvent: Array<TrackingEvent>,
): void {
  const [initialDate, _] = useState(new Date());

  useEffect(() => {
    let isTrackerSent = false;
    const handleBeforeUnload = () => {
      const track = () => {
        if (!isTrackerSent) {
          isTrackerSent = true;

          trackOnDestroy(trackingEvent, initialDate);
        }
      };
      track();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      handleBeforeUnload();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [initialDate, trackingEvent]);
}
