import type { ReactElement } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./Snackbar.module.scss";
import classNames from "classnames";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import useTranslation from "next-translate/useTranslation";
import { SNACKBAR_IMAGE_PROPS } from "./snackBarImageProps";

export enum SeverityType {
  success = "success",
  error = "error",
  unknown = "unknown",
}

export const DEFAULT_NOTIFICATION_TIMEOUT_MS = 5_000;
export interface SnackbarProps {
  /**
   * If true, snackbar is shown.
   */
  open: boolean;

  /**
   * A message to be shown.
   */
  message?: string;

  /**
   * Type of the notification.
   */
  type: SeverityType;

  /**
   * When alert should be hidden (in ms).
   */
  autoHideDuration?: number;

  /**
   * Notification should be auto-closed regardless of its type.
   */
  dismissible?: boolean;

  /**
   * Callback to be fired when notification is closed.
   */
  onClose?: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  type,
  autoHideDuration,
  dismissible = false,
  onClose,
}): ReactElement => {
  const timeoutId = useRef<number | null>(null);
  const { t } = useTranslation("web-payments");

  const dismiss = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const clearTimer = () => {
    if (timeoutId.current !== null) {
      window.clearTimeout(timeoutId.current);
    }
  };

  useEffect(() => {
    // Snackbar will be closed automatically only in case of success by default.
    // If we explicitly said that modal is dismissible, it will be closed regardless of its type.
    if (
      (type == "success" || dismissible) &&
      open &&
      autoHideDuration !== undefined
    ) {
      timeoutId.current = window.setTimeout(dismiss, autoHideDuration);
    }

    return clearTimer;
  }, [open, autoHideDuration, message, type, dismissible, dismiss]);

  return (
    <div
      role="alert"
      className={classNames(styles.snackbar, {
        [String(styles.closed)]: !open,
      })}
    >
      <div className={styles.wrapper}>
        <span className={styles.message}>{message}</span>
        {type === SeverityType.error && (
          <ImageWithFallback
            {...SNACKBAR_IMAGE_PROPS}
            src={"https://images.onefootball.com/cw/icons/warning-light.svg"}
            darkModeSrc={
              "https://images.onefootball.com/cw/icons/warning-dark.svg"
            }
            className={styles.iconImage}
          />
        )}
        {type === SeverityType.success && (
          <ImageWithFallback
            {...SNACKBAR_IMAGE_PROPS}
            src={"https://images.onefootball.com/cw/icons/check-light.svg"}
            darkModeSrc={
              "https://images.onefootball.com/cw/icons/check-dark.svg"
            }
            className={styles.iconImage}
          />
        )}
        <button
          className={styles.closeButton}
          aria-label={t`CLOSE_AREA_LABEL`}
          onClick={dismiss}
        >
          <div className={styles.closeButtonIcon}>
            <ImageWithFallback
              {...SNACKBAR_IMAGE_PROPS}
              src={"https://images.onefootball.com/cw/icons/close-light.svg"}
              darkModeSrc={
                "https://images.onefootball.com/cw/icons/close-dark.svg"
              }
              className={styles.iconImage}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export { Snackbar };
